import React, { useState, useEffect } from "react";
import "./Persona.css";
import {
  useGetConfigurationQuery,
  useGetDepartmentsQuery,
  useGetPersonaTonesQuery,
  useUpdateConfigurationMutation,
} from "../ConfigurationSlice";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

export default function Persona({ subType }) {
  const navigate = useNavigate();

  const [configuration, setConfiguration] = useState({});
  const [departments, setDepartments] = useState([]);
  const [personaTones, setPersonaTones] = useState([]);
  const [roleType, setRoleType] = useState("");
  const [func, setFunc] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [proficiency, setProficiency] = useState("");
  const [personaToneId, setPersonaToneId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState(0);
  const [productOrService, setProductOrService] = useState("");
  const [projectSpecialization, setProjectSpecialization] = useState("");
  const [statement, setStatement] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const {
    data: configurationData,
    isLoading: configurationIsLoading,
    isFetching: configurationIsFetching,
    isSuccess: configurationIsSuccess,
    isError: configurationIsError,
    error: configurationError,
    refetch: configurationRefetch,
  } = useGetConfigurationQuery();

  const {
    data: departmentData,
    isLoading: departmentIsLoading,
    isFetching: departmentIsFetching,
    isSuccess: departmentIsSuccess,
    isError: departmentIsError,
    error: departmentError,
    refetch: departmentRefetch,
  } = useGetDepartmentsQuery();

  const {
    data: personaTonesData,
    isLoading: personaTonesIsLoading,
    isFetching: personaTonesIsFetching,
    isSuccess: personaTonesIsSuccess,
    isError: personaTonesIsError,
    error: personaTonesError,
    refetch: personaTonesRefetch,
  } = useGetPersonaTonesQuery();

  const [
    updateConfiguration,
    {
      isLoading: updateConfigurationIsLoading,
      isSuccess: updateConfigurationIsSuccess,
      isError: updateConfigurationIsError,
      error: updateConfigurationError,
    },
  ] = useUpdateConfigurationMutation();

  useEffect(() => {
    if (configurationData) {
      const data = configurationData.ids.map((id) => {
        return configurationData.entities[id];
      });
      setConfiguration(data[0]);
    }
    if (configurationError) {
      toast.error(configurationError?.data?.Message, {
        duration: 2000,
      });
      return;
    }
  }, [configurationData, configurationError]);

  useEffect(() => {
    toast.remove();
  }, []);

  useEffect(() => {
    if (departmentData) {
      const data = departmentData.ids.map((id) => {
        return departmentData.entities[id];
      });
      setDepartments(data);
    }
  }, [departmentData]);

  useEffect(() => {
    if (personaTonesData) {
      const data = personaTonesData.ids.map((id) => {
        return personaTonesData.entities[id];
      });
      setPersonaTones(data);
    }
  }, [personaTonesData]);

  useEffect(() => {
    if (configuration) {
      setRoleType(configuration.roleType);
      setFunc(configuration.function);
      setProficiency(configuration.technicalProficiency);
      setDepartmentId(
        departments?.filter(
          (item) => item?.id === configuration.department?.id
        )[0]?.id
      );
      setPersonaToneId(
        personaTones?.filter(
          (item) => item?.id === configuration.personaTone?.id
        )[0]?.id
      );
      setCompanyName(configuration.companyName);
      setIndustry(configuration.industrySector);
      setNumberOfEmployees(configuration.numberOfEmployee);
      setProductOrService(configuration.primaryProduct);
      setProjectSpecialization(configuration.projectSpecialization);
      setStatement(configuration.description);
    }
  }, [departments, configuration, personaTones]);

  useEffect(() => {
    if (configuration) {
      const cannotUpdate = Boolean(
        configuration.roleType === roleType &&
          configuration.function === func &&
          configuration.technicalProficiency === proficiency &&
          configuration.companyName === companyName &&
          configuration.industrySector === industry &&
          configuration.numberOfEmployee === numberOfEmployees &&
          configuration.primaryProduct === productOrService &&
          configuration.projectSpecialization === projectSpecialization &&
          configuration.description === statement &&
          configuration.department?.id === departmentId &&
          configuration.personaTone?.id === personaToneId
      );

      setIsButtonDisabled(cannotUpdate);
    }
  }, [
    configuration,
    companyName,
    industry,
    numberOfEmployees,
    projectSpecialization,
    productOrService,
    statement,
    roleType,
    func,
    proficiency,
    departmentId,
    personaToneId,
  ]);

  console.log(numberOfEmployees);
  console.log(configuration.numberOfEmployee);
  console.log(configuration.numberOfEmployee === numberOfEmployees);

  const handleOnContinue = async () => {
    const canSave = Boolean(
      companyName &&
        industry &&
        numberOfEmployees &&
        projectSpecialization &&
        productOrService &&
        statement &&
        roleType &&
        func &&
        proficiency &&
        departmentId &&
        personaToneId
    );

    if (!canSave) {
      if (companyName === "") {
        toast.error("Company Name is required...!");
      }
      if (industry === "") {
        toast.error("Industry Sector is required...!");
      }
      if (numberOfEmployees === "") {
        toast.error("Number Of Employees is required...!");
      }
      if (projectSpecialization === "") {
        toast.error("Project Specialization is required...!");
      }
      if (productOrService === "") {
        toast.error("Product or Service is required...!");
      }
      if (statement === "") {
        toast.error("Statement is required...!");
      }
      if (roleType === "") {
        toast.error("Role Type is required...!");
      }
      if (func === "") {
        toast.error("Function is required...!");
      }
      if (proficiency === "") {
        toast.error("Technical Proficiency is required...!");
      }
      if (proficiency === "") {
        toast.error("Technical Proficiency is required...!");
      }

      return;
    }

    try {
      const response = await updateConfiguration({
        companyName: companyName,
        industrySector: industry,
        numberOfEmployee: numberOfEmployees,
        projectSpecialization: projectSpecialization,
        primaryProduct: productOrService,
        description: statement,
        roleType: roleType,
        function: func,
        technicalProficiency: proficiency,
        departmentId: departmentId,
        personaToneId: personaToneId,
      });

      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 3000,
        });
        return;
      }
      toast.success("Configuration Successfully Updated", {
        duration: 3000,
      });
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 3000,
      });
    }
  };

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "CreateLink",
      "Image",
      "Copy",
      "SourceCode",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
    type: "Expand",
  };

  if (subType === "persona") {
    return (
      <div className="persona pt-4">
        {(configurationIsFetching ||
          departmentIsFetching ||
          personaTonesIsFetching) && <LoadingSpinner />}
        <Toaster
          position="top-right"
          toastOptions={{
            success: {
              iconTheme: {
                primary: "#180080",
                secondary: "#f3f3f3",
              },
            },
          }}
        />
        <div className="card">
          <div className="card-body p-5">
            <div className="card inner-card">
              <div className="card-body m-4">
                <div className="row">
                  <div className="col-6 ps-0">
                    <h5 className="p-0">Role Type</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={roleType}
                      onChange={(e) => setRoleType(e.target.value)}
                    />
                  </div>
                  <div className="col-6 pe-0">
                    <h5 className="p-0">Function</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={func}
                      onChange={(e) => setFunc(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6 ps-0">
                    <h5 className="p-0">Department</h5>
                    <select
                      class="form-select"
                      aria-label="department"
                      onChange={(e) =>
                        setDepartmentId(parseInt(e.target.value))
                      }
                      value={departmentId}
                    >
                      {departments.map((department) => (
                        <option key={department.id} value={department.id}>
                          {department.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-6 pe-0">
                    <h5 className="p-0">Technical Proficiency</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={proficiency}
                      onChange={(e) => setProficiency(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6 ps-0">
                    <h5 className="p-0">Tone - Related</h5>
                    <select
                      class="form-select"
                      aria-label="tone-related"
                      onChange={(e) =>
                        setPersonaToneId(parseInt(e.target.value))
                      }
                      value={personaToneId}
                    >
                      {personaTones.map((personaTone) => (
                        <option key={personaTone.id} value={personaTone.id}>
                          {personaTone.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-6 pe-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <button
              className="btn back"
              onClick={() => navigate("/configuration/company")}
            >
              Back
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn next"
              onClick={() => navigate("/configuration/ethos")}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (subType === "company") {
    return (
      <div className="ethos pt-4">
        {(configurationIsFetching ||
          departmentIsFetching ||
          personaTonesIsFetching ||
          updateConfigurationIsLoading) && <LoadingSpinner />}
        <Toaster
          position="top-right"
          toastOptions={{
            success: {
              iconTheme: {
                primary: "#180080",
                secondary: "#f3f3f3",
              },
            },
          }}
        />
        <div className="card ethos-first">
          <div className="card-body p-5">
            <div className="card inner-card">
              <div className="card-body m-4">
                <div className="row">
                  <div className="col-6 ps-0">
                    <h5 className="p-0">Company Name</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  <div className="col-6 pe-0">
                    <h5 className="p-0">Industry Sector</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={industry}
                      onChange={(e) => setIndustry(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6 ps-0">
                    <h5 className="p-0">Number of Employees</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={numberOfEmployees}
                      onChange={(e) =>
                        setNumberOfEmployees(parseInt(e.target.value))
                      }
                    />
                  </div>
                  <div className="col-6 pe-0">
                    <h5 className="p-0">Primary Product or Service</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={productOrService}
                      onChange={(e) => setProductOrService(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6 ps-0">
                    <h5 className="p-0">Project Specialization</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={projectSpecialization}
                      onChange={(e) => setProjectSpecialization(e.target.value)}
                    />
                  </div>
                  <div className="col-6 pe-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-5">
          <div className="col-auto">
            <button
              className="btn next"
              onClick={() => navigate("/configuration/persona")}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (subType === "ethos") {
    return (
      <div className="ethos pt-4">
        {(configurationIsFetching ||
          departmentIsFetching ||
          personaTonesIsFetching ||
          updateConfigurationIsLoading) && <LoadingSpinner />}
        <Toaster
          position="top-right"
          toastOptions={{
            success: {
              iconTheme: {
                primary: "#180080",
                secondary: "#f3f3f3",
              },
            },
          }}
        />
        <div className="card ethos-first">
          <div className="card-body p-5">
            <div className="card ethos-second">
              <div className="card-body px-0">
                <div className="row">
                  <h5 className="mb-4 ms-2">Ethos Statement</h5>
                </div>
                <div className="row mt-3">
                  <RichTextEditorComponent
                    toolbarSettings={toolbarSettings}
                    placeholder="Write Ethos Statement..."
                    value={statement}
                    change={(args) =>
                      setStatement(
                        args.value
                          ? args.value.replace(/<p>/g, "").replace(/<\/p>/g, "")
                          : ""
                      )
                    }
                  >
                    <Inject
                      services={[
                        Toolbar,
                        Image,
                        Link,
                        HtmlEditor,
                        QuickToolbar,
                      ]}
                    />
                  </RichTextEditorComponent>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            <button
              className="btn back"
              onClick={() => navigate("/configuration/persona")}
            >
              Back
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn next"
              onClick={handleOnContinue}
              disabled={isButtonDisabled}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}
