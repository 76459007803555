import React, { useState, useEffect } from "react";
import CircularProgressBar from "../../components/common/CircularProgressBar/CircularProgressBar";
import "./TenderReview.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTenderQuery } from "../Dashboard/DashboardSlice";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import editIcon from "../../assets/icons/editIcon.svg";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import toast, { Toaster } from "react-hot-toast";
import { useAddTenderReviewMutation } from "../TenderSubmit/TenderSubmitSlice";

const TenderReview = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [review, setReview] = useState({});
  const [isEditReview, setIsEditReview] = useState(false);
  const [sliderValue, setSliderValue] = useState("0");

  const {
    data: tenderData,
    isLoading: tenderDataIsLoading,
    isFetching: tenderDataIsFetching,
    isSuccess: tenderDataIsSuccess,
    isError: tenderDataIsError,
    error: tenderDataError,
    refetch: tenderDataRefetch,
  } = useGetTenderQuery(params.id);

  const [
    addTenderReview,
    {
      isLoading: addTenderReviewIsLoading,
      isSuccess: addTenderReviewIsSuccess,
    },
  ] = useAddTenderReviewMutation();

  useEffect(() => {
    if (tenderData) {
      setReview(tenderData.review);
      setSliderValue(tenderData.review?.score);
    }
    if (tenderDataError) {
      toast.error(tenderDataError?.data?.Message || "Something went wrong", {
        duration: 4000,
      });
      return;
    }
  }, [tenderData, tenderDataError]);

  useEffect(() => {
    toast.remove();
  }, []);

  const handleSliderChange = (args) => {
    setSliderValue(args?.value);
  };

  console.log(tenderData);

  const handleTenderReviewUpdate = async () => {
    setIsEditReview(!isEditReview);
    try {
      const response = await addTenderReview({
        guid: params.id,
        score: sliderValue,
        feedbackComment: review.feedbackComment,
      }).unwrap();

      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 4000,
        });
        return;
      }

      toast.success("Tender Review Score Updated Successfully", {
        duration: 1500,
      });
      setTimeout(() => navigate(`/tenders/${params.title}/${params.id}`), 1700);
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 4000,
      });
    }
  };

  return (
    <div className="review-tender">
      {(tenderDataIsFetching || addTenderReviewIsLoading) && <LoadingSpinner />}
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="row">
        <div className="col">
          <div className="custom-card">
            <div className="row">
              <div className="col"></div>
              <div className="col-auto">
                <button
                  className="edit-icon"
                  onClick={() => setIsEditReview(!isEditReview)}
                >
                  <img src={editIcon} alt="edit" width={20} height={20} />
                </button>
              </div>
            </div>
            <div className="px-5 pt-5">
              <div className="text-secondary">
                <h6>Score Received</h6>
              </div>
              <div className="pt-1 p-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel
                  similique repudiandae unde voluptatum, aperiam quas neque
                  veritatis facilis, nostrum nobis eveniet saepe soluta
                  eligendi, deleniti inventore suscipit excepturi incidunt
                  fugiat!
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 pb-5">
              {!isEditReview && (
                <div className="w-75 p-5 text-center circular-progress-container">
                  <div>
                    <CircularProgressBar
                      id={1}
                      trackColor={"#E2FBD7"}
                      progressColor={"#34B53A"}
                      value={review.score}
                    />
                  </div>
                  <div className="pt-3 p-text progress-status">
                    <span
                      style={{ backgroundColor: "#34B53A" }}
                      className="status-one"
                    ></span>
                    <label>Accepted</label>
                    <span
                      style={{ backgroundColor: "#E2FBD7" }}
                      className="status-two"
                    ></span>
                    <label>Require improvement</label>
                  </div>
                </div>
              )}
              {isEditReview && (
                <div className="w-75 progress-bar-container text-secondary">
                  <div className="sliderwrap">
                    <h6 className="m-0">
                      Score <span>{sliderValue}</span>
                    </h6>
                    <SliderComponent
                      id="slider"
                      type="MinRange"
                      value={sliderValue}
                      immediateRender={true}
                      enableAnimation={true}
                      change={handleSliderChange}
                    ></SliderComponent>
                    <div className="d-flex justify-content-between progress-status-bar">
                      <div>
                        <h6>Accepted</h6>
                      </div>
                      <div>
                        <h6>Require improvement</h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <div className="custom-card">
            <div className="px-5 pt-5">
              <div className="text-secondary">
                <h6>Feedback Received</h6>
              </div>
              <div className="pt-1 p-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel
                  similique repudiandae unde voluptatum, aperiam quas neque
                  veritatis facilis, nostrum nobis eveniet saepe soluta
                  eligendi, deleniti inventore suscipit excepturi incidunt
                  fugiat!
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 pb-5">
              <div className="w-75 p-5 circular-progress-container">
                <div className="d-flex justify-content-center">
                  <div className="image-container">
                    <img
                      src={`${
                        window.location.hostname === "localhost"
                          ? `https://alpha.${process.env.REACT_APP_AUTH_URL}`
                          : `${window.location.protocol}//${
                              window.location.hostname.split(".")[0]
                            }.${process.env.REACT_APP_AUTH_URL}`
                      }/v1/companies/users/${
                        review?.createdBy?.guid
                      }/profile-picture`}
                      alt="Avatar"
                      className="avatar-img"
                    />
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <div className="p-text">
                    <h6 className="m-0">{review?.createdBy?.name}</h6>
                  </div>
                  {/* <div>
                    <p className="p-text">
                      Member of the executive board, ABUS
                    </p>
                  </div> */}
                </div>
                <section className="pt-2 p-text">
                  <p>{review?.feedbackComment}</p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between py-5 px-4">
        <div>
          <button
            className="btn back"
            onClick={() => navigate(`/tenders/${params.title}/${params.id}`)}
          >
            Back
          </button>
        </div>
        <div>
          <button
            className="btn continue"
            disabled={!isEditReview}
            onClick={handleTenderReviewUpdate}
          >
            Update Review
          </button>
        </div>
      </div>
    </div>
  );
};

export default TenderReview;
