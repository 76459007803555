import "./DataTable.css";
import React from "react";
import Table from "react-data-table-component";

const DataTable = ({
  tableHeading,
  tableData,
  pagination,
  transparent,
  selectableRows = true,
  setSelectedRows = () => {},
  setPage,
  setPageSize,
  totalRows,
  pageSize,
  isTableDisabled = false,
}) => {
  const customStyles = {
    head: {
      style: {},
    },
    rows: {
      style: {
        backgroundColor: transparent ? "#586a84" : "",
        color: "#ecebeb",
        wordWrap: "break-word",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        whiteSpace: "normal",
        wordWrap: "break-word",
        border: "0.7px solid #444444",
      },
    },
  };

  const handleSelectedRowsChange = (newSelectedRows) => {
    setSelectedRows(newSelectedRows);
  };

  const handleRowsPerPageChanges = (newPerPage) => {
    if (newPerPage) {
      setPageSize(newPerPage);
    }
  };

  const handlePageChanges = (page) => {
    if (setPage) {
      setPage(page);
    }
  };

  const paginationOptions = [5, 10, 25, 50];

  return (
    <Table
      disabled={isTableDisabled}
      columns={tableHeading}
      data={tableData}
      pagination={pagination}
      onSelectedRowsChange={handleSelectedRowsChange}
      customStyles={customStyles}
      responsive={true}
      paginationRowsPerPageOptions={paginationOptions}
      onChangePage={handlePageChanges}
      selectableRows={selectableRows}
      onChangeRowsPerPage={handleRowsPerPageChanges}
      noDataComponent={"No data to show"}
      paginationTotalRows={totalRows}
      paginationPerPage={pageSize}
    />
  );
};

export default DataTable;
