import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SubmitLogo from "../../../assets/images/icons/10.svg";
import "./SubmittedModal.css";
import { useNavigate } from "react-router-dom";

function SubmittedModal({ showModal, closeModal, navigatePage }) {
  const navigate = useNavigate();
  const getDateAndTime = () => {
    const currentDate = new Date();

    const date = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    return {
      date: month + "/" + date + "/" + year,
      time: hours + "." + minutes,
    };
  };
  return (
    <div>
      <Modal
        className="submitted"
        isOpen={showModal}
        toggle={() => closeModal(!showModal)}
        centered
        modalTransition={{ timeout: 500 }}
        size="lg"
      >
        <ModalBody>
          <div>
            <div className="d-flex justify-content-center">
              <div>
                <img src={SubmitLogo} alt="Submit-logo" />
              </div>
            </div>
            <div className="text-center submit-text">
              <h5 className="mt-2" style={{ fontWeight: "bold" }}>
                Successfully Submitted
              </h5>
              <p className="mt-3">{`on ${getDateAndTime().date} at ${
                getDateAndTime().time
              }`}</p>
              <p>
                Please review this tender and share your score and feedback with
                us.
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-submit"
            onClick={() => navigate(navigatePage)}
          >
            Give Feedback
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SubmittedModal;
