import React, { useState, useEffect, useRef } from "react";
import "./GeneralInfo.css";
import { ReactComponent as Uploader } from "../../../assets/icons/backup.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/images/Collapse Item.svg";
import { ReactComponent as PoundsIcon } from "../../../assets/icons/poundsIcon.svg";
import { useCreateTenderMutation } from "../CreateTenderSlice";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";
import toast, { Toaster } from "react-hot-toast";

export default function GeneralInfo() {
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [tenderTitle, setTenderTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tenderURL, setTenderURL] = useState("");
  const [minBudget, setMinBudget] = useState("");
  const [maxBudget, setMaxBudget] = useState("");
  const [industry, setIndustry] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [createdTenderTitle, setCreatedTenderTitle] = useState("");
  const [isbtnDisabled, setIsBtnDisabled] = useState();
  const [activeLink, setActiveLink] = useState("GeneralInfo");

  const [createTender, { isLoading: createTenderIsLoading }] =
    useCreateTenderMutation();

  useEffect(() => {
    if (
      files.length === 0 ||
      tenderTitle === "" ||
      companyName === "" ||
      tenderURL === ""
    ) {
      setIsBtnDisabled(true);
    } else {
      setIsBtnDisabled(false);
    }
  }, [
    files,
    tenderTitle,
    companyName,
    tenderURL
  ]);

  useEffect(() => {
    if (
      minBudget === "" ||
      maxBudget === "" ||
      industry === "" ||
      specialty === "" ||
      dueDate === ""
    ) {
      setIsBtnDisabled(true);
    } else {
      setIsBtnDisabled(false);
    }
  }, [minBudget, maxBudget, industry, specialty]);

  useEffect(() => {
    toast.remove();
  }, []);

  const handleFileChange = (e) => {
    let tempArray = [];
    if (e.target.files.length > 3) {
      for (let i = 0; i < 3; i++) {
        tempArray[i] = e.target.files[i];
      }
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        tempArray[i] = e.target.files[i];
      }
    }
    setFiles(tempArray);
  };

  const handleIconClick = (index) => {
    if (files.length !== 0) {
      const data = files.filter((file) => file !== files[index]);
      setFiles(data);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    let tempArray = [];
    if (e.dataTransfer.files.length > 3) {
      for (let i = 0; i < 3; i++) {
        tempArray[i] = e.dataTransfer.files[i];
      }
    } else {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        tempArray[i] = e.dataTransfer.files[i];
      }
    }
    setFiles(tempArray);
  };

  const handleReset = () => {
    setFiles([]);
    setTenderTitle("");
    setCompanyName("");
    setTenderURL("");
    setMinBudget("");
    setMaxBudget("");
    setIndustry("");
    setSpecialty("");
    setDueDate("");
    setDueDate();
  };

  const handleCreateTenderOnClick = async () => {
    const canSave = Boolean(
      tenderTitle &&
        companyName &&
        tenderURL &&
        minBudget &&
        maxBudget &&
        industry &&
        specialty &&
        files &&
        dueDate
    );

    if (!canSave) {
      if (industry === "") {
        toast.error("Industry is required...!");
      }
      if (tenderTitle === "") {
        toast.error("Tender Title is required...!");
      }
      if (companyName === "") {
        toast.error("Company Name is required...!");
      }
      if (tenderURL === "") {
        toast.error("Tender URL is required...!");
      }
      if (minBudget === "") {
        toast.error("Min Budget is required...!");
      }
      if (maxBudget === "") {
        toast.error("Max Budget is required...!");
      }
      if (specialty === "") {
        toast.error("Specialty is required...!");
      }
      if (dueDate === "") {
        toast.error("Due Date is required...!");
      }
      if (files.length === 0) {
        toast.error("Tender Document is required...!");
      }
      return;
    }

    try {
      const response = await createTender({
        Name: tenderTitle,
        Summary: null,
        CompanyName: companyName,
        Url: tenderURL,
        GoogleDriveLocation: `${companyName}/${tenderTitle}`,
        MinBudget: minBudget,
        MaxBudget: maxBudget,
        Industry: industry,
        Speciality: specialty,
        DueDate: dueDate,
        IsPrivate: 'true',
        File: files[0],
      });
      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 3000,
        });
        return;
      }
      toast.success("Tender Created Successfully", {
        duration: 1500,
      });
      setTimeout(
        () =>
          navigate(`/tenders/${createdTenderTitle}/${response?.data?.guid}`),
        1500
      );
      handleReset();
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 3000,
      });
    }
  };

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="Tender-Nav-link-container d-flex pb-4">
        <div
          className={`Nav-link ${activeLink === "GeneralInfo" ? "active" : ""}`}
          onClick={() => handleNavLinkClick("GeneralInfo")}
        >
          <h5>General Info</h5>
        </div>
        <div
          className={`Nav-link mx-5 ${
            activeLink === "Requirements" ? "active" : ""
          }`}
          onClick={() => {
            if (!isbtnDisabled) {
              handleNavLinkClick("Requirements");
            }
          }}
        >
          <h5>Requirements</h5>
        </div>
      </div>
      {activeLink === "GeneralInfo" ? (
        <div className="general-info">
          <div className="card">
            <div className="card-body p-5">
              <div className="card inner-card">
                <div className="card-body m-4">
                  <div className="row">
                    <div className="col-6 ps-0">
                      <h5 className="p-0">Tender Title</h5>
                      <input
                        value={tenderTitle}
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setTenderTitle(e.target.value);
                          setCreatedTenderTitle(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-6 pe-3">
                      <h5 className="p-0">Tender URL</h5>
                      <input
                        value={tenderURL}
                        type="text"
                        className="form-control"
                        onChange={(e) => setTenderURL(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 ps-0">
                      <h5 className="p-0">Company Name</h5>
                      <input
                        value={companyName}
                        type="text"
                        className="form-control"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <h5 className="p-0">Attach Tender Document</h5>
                    {files.length !== 0 && (
                      <div className="uploaded-files">
                        {files?.map((file, index) => (
                          <div className="row" key={index}>
                            <div className="col-4">{file.name}</div>
                            <div className="col-8">
                              <CollapseIcon
                                className="ms-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleIconClick(index)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div
                      className="uploader"
                      onDrop={handleDrop}
                      onDragOver={(e) => e.preventDefault()}
                      onClick={() =>
                        document.querySelector(".input-file").click()
                      }
                    >
                      <input
                        ref={fileRef}
                        type="file"
                        className="input-file"
                        hidden
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                        onClick={() => (fileRef.current.value = "")}
                      />
                      <Uploader className="mb-2" />
                      <p className="m-0">Drag and drop your file here</p>
                      <p className="m-0">or click to upload</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col"></div>
            <div className="col-auto">
              <button
                className="btn next mb-3"
                disabled={isbtnDisabled}
                onClick={() => {
                  setActiveLink("Requirements");
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="persona requirments">
          <div className="card">
            {createTenderIsLoading ? <LoadingSpinner /> : false}
            <div className="card-body p-5">
              <div className="card inner-card">
                <div className="card-body m-4">
                  <div className="row">
                    <div className="col-6 ps-0 input-container">
                      <h5 className="p-0">Min Budget</h5>
                      <input
                        type="text"
                        className="form-control input-field"
                        value={minBudget}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^\d*$/.test(inputValue)) {
                            setMinBudget(inputValue);
                          } else {
                            toast.error("Please enter only numeric values", {
                              duration: 3000,
                            });
                          }
                        }}
                      />
                      <PoundsIcon className="pounds-icon" />
                    </div>
                    <div className="col-6 pe-0 input-container">
                      <h5 className="p-0">Max Budget</h5>
                      <input
                        type="text"
                        className="form-control input-field"
                        value={maxBudget}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^\d*$/.test(inputValue)) {
                            setMaxBudget(inputValue);
                          } else {
                            toast.error("Please enter only numeric values", {
                              duration: 3000,
                            });
                          }
                        }}
                      />
                      <PoundsIcon className="pounds-icon" />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6 ps-0">
                      <h5 className="p-0">Industry</h5>
                      <input
                        type="text"
                        className="form-control"
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                      />
                    </div>
                    <div className="col-6 pe-0">
                      <h5 className="p-0">Specialty</h5>
                      <input
                        type="text"
                        className="form-control"
                        value={specialty}
                        onChange={(e) => setSpecialty(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6 ps-0">
                      <h5 className="p-0">Due Date</h5>
                      <div className="input-group date-picker-container">
                        <input
                          type="date"
                          className="form-control date-picker"
                          value={dueDate}
                          onChange={(e) => setDueDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-6 pe-0"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col"></div>
            <div className="d-flex justify-content-between mb-3">
              <div>
                <button
                  className="back-btn fw-bold px-4"
                  onClick={() => {
                    setActiveLink("GeneralInfo");
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                <button
                  className="btn next"
                  onClick={handleCreateTenderOnClick}
                  disabled={isbtnDisabled}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
