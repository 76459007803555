import React, { useEffect, useState } from "react";
import "./SearchAnswers.css";
import searchIcon from "../../assets/icons/Icons-1.svg";
import timeIcon from "../../assets/images/icons/Icons (3).svg";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useGetResearchAnswersQuery } from "./SearchAnswersSlice";
import CustomSpinner from "../../components/common/LoadingSpinner/CustomSpinner";

export default function SearchAnswers() {
  const navigate = useNavigate();

  const [activeLink, setActiveLink] = useState("Knowledge Based Assistance");
  const [question, setQuestion] = useState(null);
  const [assistanceId, setAssistanceId] = useState(3);
  const [researchAnswers, setResearchAnswers] = useState();
  const [suggestedAnswer, setSuggestedAnswer] = useState("");

  const {
    data: researchData,
    isLoading: researchDataIsLoading,
    isFetching: researchDataIsFetching,
    isSuccess: researchDataIsSuccess,
    isError: researchDataIsError,
    error: researchDataError,
    refetch: researchDataRefetch,
  } = useGetResearchAnswersQuery({
    question:question,
    assistanceTypeId:assistanceId
  });

  useEffect(() => {
    if (researchData) {
      const data = researchData.ids.map((id) => {
        return researchData.entities[id];
      });
      setResearchAnswers(data[0]);
    }
    if (researchDataError && researchDataError?.status===403) {
      toast.error(researchDataError?.data?.Message || "Something went wrong", {
        duration: 4000,
      });
      return;
    }
  }, [researchData, researchDataError]);

  useEffect(() => {
    toast.remove();
  }, []);

  useEffect(() => {
    if (researchAnswers) {
      switch (activeLink) {
        case "Knowledge Based Assistance":
          setSuggestedAnswer(researchAnswers?.answer);
          break;
        case "AI Assistance":
          setSuggestedAnswer(researchAnswers?.answer);
          break;
        case "Web Assistance":
          setSuggestedAnswer(researchAnswers?.answer);
          break;
        default:
          break;
      }
    }
  }, [activeLink, researchAnswers]);

  const handleNavLinkClick = (link,id) => {
    setActiveLink(link);
    setAssistanceId(id);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.target.value === "") {
        toast.error("Your Question is required...!");
        return;
      }
      setQuestion(e.target.value);
    }
  };

  return (
    <div className="search-answers">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div>
        <div className="Nav-link-container d-flex pt-2 pb-4">
          <div
            className={`Nav-link ${
              activeLink === "Knowledge Based Assistance" ? "active" : ""
            }`}
            onClick={() => handleNavLinkClick("Knowledge Based Assistance", 3)}
          >
            <h6>Knowledge Base Assistance</h6>
          </div>
          <div
            className={`Nav-link mx-5 ${
              activeLink === "AI Assistance" ? "active" : ""
            }`}
            onClick={() => handleNavLinkClick("AI Assistance", 1)}
          >
            <h6>AI Assistance</h6>
          </div>
          <div
            className={`Nav-link ${
              activeLink === "Web Assistance" ? "active" : ""
            }`}
            onClick={() => handleNavLinkClick("Web Assistance", 2)}
          >
            <h6>WebBased Assistance</h6>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="custom-card">
            <div className="pt-4">
              <div className="px-5">
                <div className="d-flex justify-content-center position-relative search-bar">
                  <img
                    className="search position-absolute"
                    src={searchIcon}
                    alt="search"
                    width={20}
                    height={20}
                  />
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Your Question..."
                    onKeyDown={(e) => handleKeyPress(e)}
                  />
                  <img
                    className="time position-absolute"
                    src={timeIcon}
                    alt="search"
                    width={20}
                    height={20}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center px-5 mt-5 pb-5">
                <div className="question-container w-100 p-4 card">
                  <div
                    className="question-body p-5 justify-content-center text-justify"
                    style={{ color: "white", opacity: 0.7 }}
                  >
                    {researchDataIsFetching && (
                      <CustomSpinner type={activeLink} />
                    )}
                    {researchDataIsFetching ? (
                      ""
                    ) : suggestedAnswer ? (
                      suggestedAnswer
                    ) : (
                      <h6 className="text-center pt-4">No Answers</h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
