import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout/Layout";
import Dashboard from "./features/Dashboard/Dashboard";
import UpgradeTender from "./features/Tenders/UpgradeTender";
import DashboardLayout from "./components/DashboardLayout/DashboardLayout";
import KnowledgeBase from "./features/KnowledgeBase/KnowledgeBase";
import GeneralInfo from "./features/CreateTender/GeneralInfo/GeneralInfo";
import TenderSubmit from "./features/TenderSubmit/TenderSubmit";
import FinalizedAnswer from "./features/FinalizedAnswers/FinalizedAnswer";
import ResearchLayout from "./components/ResearchLayout/ResearchLayout";
import TenderReview from "./features/TenderReview/TenderReview";
import ConfigurationLayout from "./components/ConfigurationLayout/ConfigurationLayout";
import Persona from "./features/Configuration/Persona/Persona";
import TendersLayout from "./components/TendersLayout/TendersLayout";
import RequireAuth from "./features/auth/RequireAuth";
import LoginPage from "./pages/LoginPage/LoginPage";
import SearchAnswers from "./features/SearchAnswers/SearchAnswers";
import GenerateAnswers from "./features/GenerateAnswers/GenerateAnswers";

function App() {
  return (
    <Routes>
      <Route index path="/login" element={<LoginPage />} />
      <Route element={<RequireAuth />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="tenders" />} />
          <Route path="tenders" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path=":title/:id" element={<TendersLayout />}>
              <Route index element={<UpgradeTender />} />
              <Route path="generate-answers" element={<GenerateAnswers />} />
              <Route path="finalized-answers" element={<FinalizedAnswer />} />
              <Route path="tender-submit" element={<TenderSubmit />} />
              <Route path="tender-review" element={<TenderReview />} />
            </Route>
            <Route path="create-tender">
              <Route index element={<GeneralInfo />} />
            </Route>
          </Route>
          <Route path="research" element={<ResearchLayout />}>
            <Route index element={<SearchAnswers />} />
          </Route>
          <Route path="knowledge-base" element={<KnowledgeBase />} />
          <Route path="configuration" element={<ConfigurationLayout />}>
            <Route index element={<Navigate to="company" />} />
            <Route path="company" element={<Persona subType={"company"}/>} />
            <Route path="persona" element={<Persona subType={"persona"} />} />
            <Route path="ethos" element={<Persona subType={"ethos"}/>} />
          </Route>
          <Route path="work-book" element={<h1>Work Book</h1>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
