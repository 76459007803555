import { React, useState, useEffect } from "react";
import { ReactComponent as ExpandIcon } from "../../assets/images/Expand Item.svg";
import { ReactComponent as CollapseIcon } from "../../assets/images/Collapse Item.svg";
import PaginationComponent from "../../components/common/Pagination/PaginationComponent";
import "./GenerateAnswers.css";
import {
  useUpdateQuestionsMutation,
  useGetAllQuestionsQuery,
  useGetQuestionAnswersQuery,
} from "../Dashboard/DashboardSlice";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const GenerateAnswers = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [activeLink, setActiveLink] = useState("Knowledge Based Assistance");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [questionGuid, setQuestionGuid] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [questionName, setQuestionName] = useState("");
  const [questionArray, setQuestionArray] = useState([]);
  const [kbAnswerArray, setKbAnswerArray] = useState([]);
  const [aiAnswerArray, setAiAnswerArray] = useState([]);
  const [webAnswerArray, setWebAnswerArray] = useState([]);
  const [activeArray, setActiveArray] = useState([]);

  const {
    data: questionData,
    isLoading: questionDataIsLoading,
    isFetching: questionDataIsFetching,
    isSuccess: questionDataIsSuccess,
    isError: questionDataIsError,
    error: questionDataError,
    refetch: questionDataRefetch,
  } = useGetAllQuestionsQuery({
    guid: params.id,
    page: page,
    pageSize: pageSize,
  });

  const {
    data: questionAnswersData,
    isLoading: questionAnswersDataIsLoading,
    isFetching: questionAnswersDataIsFetching,
    isSuccess: questionAnswersDataIsSuccess,
    isError: questionAnswersDataIsError,
    error: questionAnswersDataError,
    refetch: questionAnswersDataRefetch,
  } = useGetQuestionAnswersQuery(questionGuid);

  const [
    updateQuestions,
    {
      isLoading: updateQuestionsIsLoading,
      isSuccess: updateQuestionsIsSuccess,
      isError: updateQuestionsIsError,
    },
  ] = useUpdateQuestionsMutation();

  useEffect(() => {
    if (questionData) {
      setQuestions(questionData.items);
      setTotalRecords(questionData.total);
    }
    if(questionDataError){
      toast.error(questionDataError?.data?.Message || "Something went wrong", {
        duration: 4000,
      });
      return;
    }
  }, [questionData, questionDataError]);

  useEffect(() => {
    toast.remove();
  }, []);

  useEffect(() => {
    if (questionAnswersData) {
      const data = questionAnswersData.ids.map((id) => {
        return questionAnswersData.entities[id];
      });
      setAnswers(data[0]);
    }
    if(questionAnswersDataError){
      toast.error(questionAnswersDataError?.data?.Message || "Something went wrong", {
        duration: 4000,
      });
      return;
    }
  }, [questionAnswersData, questionAnswersDataError]);

  useEffect(() => {
    if (answers) {
      if (kbAnswerArray?.length) {
        if (!kbAnswerArray.some((item) => item.guid === answers.guid)) {
          setKbAnswerArray([
            ...kbAnswerArray,
            {
              question: answers.question,
              assistanceTypeId: answers.kbAnswer?.assistanceTypeId,
              answer: answers.kbAnswer?.answer,
            },
          ]);
        }
      } else {
        setKbAnswerArray([
          {
            question: answers.question,
            assistanceTypeId: answers.kbAnswer?.assistanceTypeId,
            answer: answers.kbAnswer?.answer,
          },
        ]);
      }

      if (aiAnswerArray?.length) {
        if (!aiAnswerArray.some((item) => item.guid === answers.guid)) {
          setAiAnswerArray([
            ...aiAnswerArray,
            {
              question: answers.question,
              assistanceTypeId: answers.aiAnswer?.assistanceTypeId,
              answer: answers.aiAnswer?.answer,
            },
          ]);
        }
      } else {
        setAiAnswerArray([
          {
            question: answers.question,
            assistanceTypeId: answers.aiAnswer?.assistanceTypeId,
            answer: answers.aiAnswer?.answer,
          },
        ]);
      }

      if (webAnswerArray?.length) {
        if (!webAnswerArray.some((item) => item.guid === answers.guid)) {
          setWebAnswerArray([
            ...webAnswerArray,
            {
              question: answers.question,
              assistanceTypeId: answers.webAnswer?.assistanceTypeId,
              answer: answers.webAnswer?.answer,
            },
          ]);
        }
      } else {
        setWebAnswerArray([
          {
            question: answers.question,
            assistanceTypeId: answers.webAnswer?.assistanceTypeId,
            answer: answers.webAnswer?.answer,
          },
        ]);
      }
    }
  }, [answers]);

  useEffect(() => {
    if (activeLink) {
      switch (activeLink) {
        case "Knowledge Based Assistance":
          setActiveArray(kbAnswerArray);
          break;
        case "AI Assistance":
          setActiveArray(aiAnswerArray);
          break;
        case "Web Assistance":
          setActiveArray(webAnswerArray);
          break;
        default:
          break;
      }
    }
  }, [activeLink, kbAnswerArray, aiAnswerArray, webAnswerArray]);

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleExpandClick = (question) => {
    setQuestionGuid(question.guid);
    setQuestionName(question.question);
  };

  const handleCheck = (e, question) => {
    if (e.target.checked) {
      const filteredArray = questionArray?.filter(
        (item) => item.guid !== question.guid
      );
      setQuestionArray(
        filteredArray.length
          ? [
              ...filteredArray,
              {
                guid: question.guid,
                question: question.question,
                suggestedAnswer: activeArray?.filter(
                  (item) => item.question === question.question
                )[0]?.answer,
                assistanceTypeId: activeArray?.filter(
                  (item) => item.question === question.question
                )[0]?.assistanceTypeId,
                additionalNote: question.additionalNote,
                finalizeAnswer: activeArray?.filter(
                  (item) => item.question === question.question
                )[0]?.answer,
              },
            ]
          : [
              {
                guid: question.guid,
                question: question.question,
                suggestedAnswer: activeArray?.filter(
                  (item) => item.question === question.question
                )[0]?.answer,
                assistanceTypeId: activeArray?.filter(
                  (item) => item.question === question.question
                )[0]?.assistanceTypeId,
                additionalNote: question.additionalNote,
                finalizeAnswer: activeArray?.filter(
                  (item) => item.question === question.question
                )[0]?.answer,
              },
            ]
      );
    } else {
      const tempArray = questionArray?.filter(
        (item) => item?.guid !== question.guid
      );
      setQuestionArray(tempArray);
    }
  };

  const handleContinueClick = async () => {
    if (questionArray.length === 0) {
      toast.error("Questions are required...!");
      return;
    }

    try {
      const response = await updateQuestions({
        guid: params.id,
        questions: questionArray,
      });

      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 2500,
        });
        return;
      }
      toast.success("Questions Successfully Updated", {
        duration: 1500,
      });
      setTimeout(
        () =>
          navigate(
            `/dashboard/tenders/${params.title}/${params.id}/finalized-answers`
          ),
        2500
      );
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 3000,
      });
    }
  };

  return (
    <div className="review-tender">
      {(questionDataIsFetching || updateQuestionsIsLoading) && (
        <LoadingSpinner />
      )}
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="Nav-link-container d-flex px-4 pt-4 pb-4">
        <div
          className={`Nav-link ${
            activeLink === "Knowledge Based Assistance" ? "active" : ""
          }`}
          onClick={() => handleNavLinkClick("Knowledge Based Assistance")}
        >
          <h6>Knowledge Base Assistance</h6>
        </div>
        <div
          className={`Nav-link mx-5 ${
            activeLink === "AI Assistance" ? "active" : ""
          }`}
          onClick={() => handleNavLinkClick("AI Assistance")}
        >
          <h6>AI Assistance</h6>
        </div>
        <div
          className={`Nav-link ${
            activeLink === "Web Assistance" ? "active" : ""
          }`}
          onClick={() => handleNavLinkClick("Web Assistance")}
        >
          <h6>WebBased Assistance</h6>
        </div>
      </div>
      <div className="row mt-2 px-4">
        <div className="col">
          <div className="custom-card">
            <div className="px-5 pt-5">
              <div className="text-secondary">
                <h6>Question list</h6>
              </div>
              <div className="pt-1 p-text">
                <p>
                  There are questions corresponding to the template. You can
                  select the best answer or request a combination of answers for
                  the final document.
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 pb-5">
              <div className="question-container w-75 p-4 card">
                <div className="question-body pt-4 justify-content-center">
                  <div className="w-100 px-3">
                    {questions?.length ? (
                      questions.map((question, index) => (
                        <div key={question.guid} className="row mb-5">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-11 p-0 text-secondary">
                              <div className="d-flex">
                                <div className="px-3 m-0">
                                  <input
                                    key={question.guid + activeLink}
                                    type="checkbox"
                                    checked={questionArray?.some(
                                      (item) =>
                                        item.guid === question.guid &&
                                        item.assistanceTypeId ===
                                          activeArray[0]?.assistanceTypeId
                                    )}
                                    onChange={(e) => handleCheck(e, question)}
                                    disabled={
                                      activeArray.length === 0 ||
                                      !activeArray?.some(
                                        (item) =>
                                          item.question === question.question
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                <div>
                                  <h6 className="fw-bold">
                                    {index + 1 + (page - 1) * pageSize}.{" "}
                                    {question.question}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-auto d-flex">
                              {questionName === question.question ? (
                                <CollapseIcon
                                  className="collapse-icon"
                                  onClick={() => setQuestionName(null)}
                                />
                              ) : (
                                <ExpandIcon
                                  className="expand-icon"
                                  onClick={() => handleExpandClick(question)}
                                />
                              )}
                            </div>
                            {questionName === question.question && (
                              <div className="row answer-container mt-4 p-text ms-5">
                                {questionAnswersDataIsFetching && (
                                  <LoadingSpinner />
                                )}
                                {
                                  activeArray?.filter(
                                    (item) =>
                                      item.question === question.question
                                  )[0]?.answer
                                }
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No questions</p>
                    )}

                    <div className="row">
                      <PaginationComponent
                        setPage={setPage}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between py-5 px-4">
        <div>
          <button
            className="btn back"
            onClick={() => navigate(`/dashboard/tenders/${params.id}`)}
          >
            Back
          </button>
        </div>
        <div>
          <button className="btn continue" onClick={handleContinueClick}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenerateAnswers;
