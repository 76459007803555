import React from "react";
import {
  ProgressBarComponent,
  ProgressBarAnnotationsDirective,
  ProgressBarAnnotationDirective,
  Inject,
  ProgressAnnotation,
} from "@syncfusion/ej2-react-progressbar";

const CircularProgressBar = ({ trackColor, progressColor, id, value }) => {
  let content =
    '<div id="point1" style="font-size:16px;color:#ffffff;fill:#ffffff;background-color:#34B53A;border-radius:90%;padding:15px;text-align:center"><span></span></div>';

  return (
    <div>
      <ProgressBarComponent
        id="circular"
        type="Circular"
        height="185px"
        width="100%"
        trackThickness={12}
        progressThickness={12}
        value={value}
        enableRtl={false}
        showProgressValue={true}
        trackColor={trackColor}
        radius="100%"
        progressColor={progressColor}
        cornerRadius="Round"
        animation={{
          enable: true,
          duration: 2000,
          delay: 0,
        }}
      >
        <Inject services={[ProgressAnnotation]} />
        <ProgressBarAnnotationsDirective>
          <ProgressBarAnnotationDirective
            content={content}
          ></ProgressBarAnnotationDirective>
        </ProgressBarAnnotationsDirective>
      </ProgressBarComponent>
    </div>
  );
};

export default CircularProgressBar;
