import { React, useEffect, useState } from "react";
import "./FinalizedAnswer.css";
import { useParams } from "react-router-dom";
import { useGetAllQuestionsQuery } from "../Dashboard/DashboardSlice";
import DataTable from "../../components/FinalizedAnswersTable/DataTable/DataTable";
import { nanoid } from "@reduxjs/toolkit";
import editIcon from "../../assets/icons/edit.svg";
import saveIcon from "../../assets/icons/save.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import deleteIconDisabled from "../../assets/icons/deleteIcon.svg";
import editIconDisabled from "../../assets/icons/editIconDisabled.svg";
import saveIconDisabled from "../../assets/icons/saveIconDisabled.svg";
import toast, { Toaster } from "react-hot-toast";

export default function FinalizedAnswer({
  questionRef,
  finalizedAnswerRef,
  isTenderSubmitted = false,
  updateFunction,
  editFunction,
  questionName,
  setDeletableQuestionGuid,
  addQuestionShowModal,
  setShowModal,
}) {
  const params = useParams();

  const [tenderQuestions, setTenderQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalRows, setTotalRows] = useState(null);

  const {
    data: questionData,
    isLoading: questionDataIsLoading,
    isFetching: questionDataIsFetching,
    isSuccess: questionDataIsSuccess,
    isError: questionDataIsError,
    error: questionDataError,
    refetch: questionDataRefetch,
  } = useGetAllQuestionsQuery({
    guid: params.id,
    page: page,
    pageSize: pageSize,
  });

  useEffect(() => {
    toast.remove();
  }, []);

  useEffect(() => {
    if (questionData) {
      const data = questionData.items.map((t) => {
        return {
          id: nanoid(),
          guid: t?.guid,
          question: t?.question,
          suggestedAnswer: t?.suggestedAnswer,
          assistanceType: t?.assistanceType,
          additionalNote: t?.additionalNote,
          finalizeAnswer: t?.finalizeAnswer,
        };
      });
      setTotalRows(questionData?.total);
      setTenderQuestions(data);
    }
    if (questionDataError) {
      toast.error(questionDataError?.data?.Message, {
        duration: 4000,
      });
      return;
    }
  }, [questionData, questionDataError]);

  const AnswersTableHeadings = [
    {
      name: "Questions",
      selector: (row) => row?.question,
      width: "300px",
      cell: (row) => (
        <textarea
          id={row.guid}
          key={row.guid}
          cols={45}
          rows={Math.round(row?.question?.length / 45)}
          ref={(element) => (questionRef.current[row.guid] = element)}
          className={`question-text-input ${
            questionName === row.question ? "editable" : ""
          }`}
          disabled={questionName !== row.question}
          defaultValue={row.question}
        />
      ),
    },
    {
      name: "Suggested Answer",
      selector: (row) => row?.suggestedAnswer,
      width: "400px",
      cell: (row) => (
        <>
          <span className="py-2 suggested-answer">{row?.suggestedAnswer}</span>
        </>
      ),
    },
    {
      name: "Finalized Answer",
      selector: (row) => row?.finalizeAnswer,
      width: "400px",
      cell: (row) => (
        <>
          <textarea
            key={row?.id}
            ref={(element) => (finalizedAnswerRef.current[row.guid] = element)}
            placeholder="type something..."
            className={`mt-2 finalizedAnswer-text-input ${
              questionName === row.question ? "editable" : ""
            }`}
            cols={48}
            rows={Math.round(row?.finalizeAnswer.length / 45)}
            defaultValue={row?.finalizeAnswer}
            disabled={questionName !== row.question}
          ></textarea>
        </>
      ),
    },
    {
      name: "Action",
      width: "122px",
      cell: (row) => (
        <div className="d-flex gap-3 options">
          <button disabled={isTenderSubmitted}>
            <img
              src={
                isTenderSubmitted || questionName === row.question
                  ? editIconDisabled
                  : editIcon
              }
              alt="edit"
              width={20}
              height={20}
              onClick={() => editFunction(row)}
            />
          </button>
          <button
            onClick={updateFunction}
            disabled={questionName !== row.question}
          >
            <img
              src={questionName === row.question ? saveIcon : saveIconDisabled}
              alt="save"
              width={20}
              height={20}
            />
          </button>
          <button
            disabled={isTenderSubmitted}
            onClick={() => {
              setShowModal(true);
              setDeletableQuestionGuid(row.guid);
            }}
          >
            <img
              src={isTenderSubmitted ? deleteIconDisabled : deleteIcon}
              alt="delete"
              width={20}
              height={20}
            />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="selected-answers">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="card">
        <div className="card-body p-5">
          <div className="row">
            <h5 className="mb-0">Finalized Answers</h5>
            <div className="col"></div>
            <div className="col-auto">
              <button
                className="btn add-question"
                disabled={isTenderSubmitted}
                onClick={() => addQuestionShowModal(true)}
              >
                Add Questions
              </button>
            </div>
          </div>
          <div>
            {tenderQuestions.length > 0 ? (
              <div className="row mt-4 answers-table">
                <DataTable
                  isTableDisabled={isTenderSubmitted}
                  selectableRows={false}
                  tableData={tenderQuestions}
                  pagination={!isTenderSubmitted}
                  tableHeading={AnswersTableHeadings}
                  setPageSize={setPageSize}
                  setPage={setPage}
                  pageSize={pageSize}
                  totalRows={totalRows}
                />
              </div>
            ) : (
              <div className="text-center fw-bold text-secondary">
                No data to show
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
