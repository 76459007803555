import React from "react";
import { PagerComponent } from "@syncfusion/ej2-react-grids";

const PaginationComponent = ({ setPage, pageSize, totalRecords }) => {
  const handlePageClick = (args) => {
    setPage(args.currentPage);
  };

  return (
    <PagerComponent
      pageSize={pageSize}
      totalRecordsCount={totalRecords}
      click={handlePageClick}
      style={{ backgroundColor: "transparent", color: "#606d87" }}
    />
  );
};

export default PaginationComponent;
