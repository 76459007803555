import { useEffect, useRef, useState } from "react";
import fileUploadIcon from "../../assets/icons/backup.svg";
import SearchBarIcon from "../../assets/icons/07.svg";
import searchIcon from "../../assets/icons/Icons-1.svg";
import DataTable from "../../components/common/DataTable/DataTable.jsx";
import { ReactComponent as CollapseIcon } from "../../assets/images/Collapse Item.svg";
import toast, { Toaster } from "react-hot-toast";
import "./KnowledgeBase.css";
import {
  useAddKnowledgeBaseDocumentsMutation,
  useGetKnowledgeBaseDocumentsQuery,
} from "./KnowledgeBaseSlice.js";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner.jsx";
import { format, parseISO } from "date-fns";

const KnowledgeBase = () => {
  const fileRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documents, setDocuments] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const {
    data: knowledgeBaseDocumentsData,
    isLoading: knowledgeBaseDocumentsDataIsLoading,
    isFetching: knowledgeBaseDocumentsDataIsFetching,
    isSuccess: knowledgeBaseDocumentsDataIsSuccess,
    isError: knowledgeBaseDocumentsDataIsError,
    error: knowledgeBaseDocumentsDataError,
    refetch: knowledgeBaseDocumentsDataRefetch,
  } = useGetKnowledgeBaseDocumentsQuery({
    page: page,
    pageSize: pageSize,
  });

  const [
    addKnowledgeBaseDocuments,
    {
      isLoading: addKnowledgeBaseDocumentsIsLoading,
      isSuccess: addKnowledgeBaseDocumentsIsSuccess,
      isError: addKnowledgeBaseDocumentsIsError,
    },
  ] = useAddKnowledgeBaseDocumentsMutation();

  const dateFomatter = (dateString) => {
    return format(parseISO(dateString), "MM/dd/yyyy");
  };

  const timeFomatter = (dateString) => {
    return format(parseISO(dateString), "HH:mm:ss");
  };

  useEffect(() => {
    toast.remove();
  }, []);

  useEffect(() => {
    if (knowledgeBaseDocumentsData) {
      const data = knowledgeBaseDocumentsData.items?.map((item) => ({
        name: item.documents?.name,
        uploader: item.documents?.createdBy?.name,
        date: item.documents?.createdOn
          ? dateFomatter(item.documents?.createdOn)
          : "-",
        time: item.documents?.createdOn
          ? timeFomatter(item.documents?.createdOn)
          : "-",
      }));
      setDocuments(data);
      setTotalRecords(knowledgeBaseDocumentsData.total);
    }
    if(knowledgeBaseDocumentsDataError){
      toast.error(knowledgeBaseDocumentsDataError?.data?.Message, {
        duration: 4000,
      });
      return;
    }
  }, [knowledgeBaseDocumentsData, knowledgeBaseDocumentsDataError]);

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 3) {
      toast.error("You can only upload up to 3 files", {
        duration: 2000,
      });
      return;
    }
    setSelectedFiles(files);
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 3) {
      toast.error("You can only upload up to 3 files", {
        duration: 2000,
      });
      return;
    }
    setSelectedFiles(files);
  };

  const removeFileonClick = (index) => {
    if (selectedFiles.length !== 0) {
      const data = selectedFiles.filter(
        (file) => file !== selectedFiles[index]
      );
      setSelectedFiles(data);
    }
  };

  const handleSubmitClick = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await addKnowledgeBaseDocuments(formData);

      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 3000,
        });
        return;
      }
      toast.success("Files Successfully Uploaded", {
        duration: 3000,
      });
      setSelectedFiles([]);
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 3000,
      });
    }
  };

  const columns = [
    {
      key: "name",
      field: "name",
      headerText: "Document Name",
      width: "150",
      alignment: "Left",
    },
    {
      key: "uploader",
      field: "uploader",
      headerText: "Uploaded By",
      width: "150",
      alignment: "Left",
    },
    {
      key: "date",
      field: "date",
      headerText: "Date",
      width: "100",
      alignment: "Left",
    },
    {
      key: "time",
      field: "time",
      headerText: "Time",
      width: "80",
      alignment: "Left",
    },
  ];

  return (
    <div className="Knowledge-Base">
      {(knowledgeBaseDocumentsDataIsFetching ||
        addKnowledgeBaseDocumentsIsLoading) && <LoadingSpinner />}
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="row file-upload-conatiner">
        <div className="col p-0">
          <div className="custom-card file-upload-card">
            <div className="row px-4 file-upload-container">
              <h6 className="p-0 py-1 file-upload-title fw-medium">
                Upload Documents
              </h6>
              <div className="row mt-1">
                {selectedFiles.length !== 0 && (
                  <div className="uploaded-files">
                    {selectedFiles?.map((file, index) => (
                      <div className="row" key={index}>
                        <div className="col-4">{file.name}</div>
                        <div className="col-8">
                          <CollapseIcon
                            className="ms-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => removeFileonClick(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className="uploader"
                  onDrop={handleFileDrop}
                  onDragOver={(e) => e.preventDefault()}
                  onClick={() => document.querySelector(".input-file").click()}
                >
                  <input
                    ref={fileRef}
                    type="file"
                    className="input-file"
                    hidden
                    multiple
                    accept=".pdf"
                    onChange={handleFileUpload}
                    onClick={() => (fileRef.current.value = "")}
                  />
                  <img
                    src={fileUploadIcon}
                    alt="Upload Icon"
                    className="upload-icon"
                  />
                  <p className="m-0">Drag and drop your file here</p>
                  <p className="m-0">or click to upload</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col"></div>
              <div className="col-auto me-3" style={{ marginTop: "-50px" }}>
                <button
                  className="btn submit"
                  onClick={handleSubmitClick}
                  disabled={!selectedFiles.length}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col">
          <div className="custom-card uploaded-fileDetails-card">
            <div className="row gap-2 justify-content-between">
              <div className="col-12 col-md-2 border-2 mt-4 px-4">
                <select
                  className="border-0 page-selector"
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <option value={5}>Display 5 Files</option>
                  <option value={10}>Display 10 Files</option>
                </select>
              </div>
              <div className="col-12 col-md-3 border-2 d-flex py-3 px-4">
                <div style={{ position: "relative" }}>
                  <div className="search-icon">
                    <img src={searchIcon} alt="Search" />
                  </div>
                  <div className="px-2">
                    <input
                      id="file-search-input"
                      type="text"
                      className="form-control custom-input w-100 h-100 pl-3"
                      placeholder="Search Document.."
                    />
                  </div>
                </div>
                <div>
                  <img src={SearchBarIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="px-1 knowledge-base-table-content">
                <DataTable
                  data={documents}
                  columns={columns}
                  height={360}
                  defaultPaging={false}
                  setPage={setPage}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBase;
