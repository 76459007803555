import React from "react";
import "./FeedbackModal.css";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import feedbackLogo from "../../../assets/icons/feedback.svg";

export default function FeedbackModal({
  showModal,
  closeModal,
  navigatePage,
  score,
}) {
  const navigate = useNavigate();
  const getDateAndTime = () => {
    const currentDate = new Date();

    const date = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    return {
      date: month + "/" + date + "/" + year,
      time: hours + "." + minutes,
    };
  };
  return (
    <div>
      <Modal
        className="feedback-modal"
        isOpen={showModal}
        toggle={() => closeModal(!showModal)}
        centered
        modalTransition={{ timeout: 500 }}
        size="lg"
      >
        <ModalBody>
          <div>
            <div className="d-flex justify-content-center">
              <div>
                <img src={feedbackLogo} alt="feedback-logo" />
              </div>
            </div>
            <div className="text-center feedback-text">
              <h5 className="mt-2" style={{ fontWeight: "bold" }}>
                Feedback Received
              </h5>
              <p className="mt-3">{`on ${getDateAndTime().date} at ${
                getDateAndTime().time
              }`}</p>
              <p>
                You earned <span>{score + "%"}</span> of the score. Your tender
                is successfull
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-review"
            onClick={() => navigate(navigatePage)}
          >
            Review
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
