import React from "react";
import "./CustomSpinner.css";

const CustomSpinner = ({ type }) => {
  return (
    <div className="custom-spinner-container">
      <div className="d-flex justify-content-center">
        <div className="custom-spinner mt-3"></div>
      </div>
      <div className="text-center fw-bold py-2">{`${type} Answer is Loading...`}</div>
    </div>
  );
};

export default CustomSpinner;
