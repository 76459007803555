import React, { useEffect, useRef, useState } from "react";
import "./UpgradeTender.css";
import { ReactComponent as DownloadIcon } from "../../assets/images/Download.svg";
import PdfIcon from "../../assets/images/pdf.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetTenderQuery,
  useSubmitTenderMutation,
} from "../Dashboard/DashboardSlice";
import { format, parseISO } from "date-fns";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import {
  useUpdateQuestionsMutation,
  useDeleteQuestionMutation,
} from "../Dashboard/DashboardSlice";
import toast, { Toaster } from "react-hot-toast";
import DeleteConfirmationModal from "../../components/common/DeleteConfirmationModal/DeleteConfirmationModal";
import AddTenderQuestionModal from "../CreateTender/AddTenderQuestionnaire/AddTenderQuestionModal";
import { useAddTenderQuestionMutation } from "../CreateTender/CreateTenderSlice";
import SubmittedModal from "../../components/common/SubmittedModal/SubmittedModal";
import FinalizedAnswer from "../FinalizedAnswers/FinalizedAnswer";

export default function UpgradeTender() {
  const params = useParams();
  const navigate = useNavigate();
  const questionRef = useRef([]);
  const finalizedAnswerRef = useRef([]);

  const [tender, setTender] = useState({});
  const [suggestedAnswer, setSuggestedAnswer] = useState("");
  const [additionalNote, setAdditionalNote] = useState("");
  const [questionName, setQuestionName] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState(null);
  const [editedQuestionGuid, setEditedQuestionGuid] = useState("");
  const [assistanceTypeId, setAssistanceTypeId] = useState(0);
  const [deletableQuestionGuid, setDeletableQuestionGuid] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newQuestions, setNewQuestions] = useState([]);
  const [addQuestionShowModal, setAddQuestionShowModal] = useState(false);
  const [answersStore, setAnswersStore] = useState([]);
  const [submitModalShow, setSubmitModalShow] = useState(false);

  const {
    data: tenderData,
    isLoading: tenderDataIsLoading,
    isFetching: tenderDataIsFetching,
    isSuccess: tenderDataIsSuccess,
    isError: tenderDataIsError,
    error: tenderDataError,
    refetch: tenderDataRefetch,
  } = useGetTenderQuery(params.id);

  const [addTenderQuestion, { isLoading: addTenderQuestionIsLoading }] =
    useAddTenderQuestionMutation();

  const [submitTender, { isLoading: submitTenderIsLoading }] =
    useSubmitTenderMutation();

  const [
    updateQuestions,
    {
      isLoading: updateQuestionsIsLoading,
      isSuccess: updateQuestionsIsSuccess,
      isError: updateQuestionsIsError,
    },
  ] = useUpdateQuestionsMutation();

  const [
    deleteQuestion,
    {
      isLoading: deleteQuestionIsLoading,
      isSuccess: deleteQuestionIsSuccess,
      isError: deleteQuestionIsError,
    },
  ] = useDeleteQuestionMutation();

  const dateFomatter = (dateString) => {
    return format(parseISO(dateString), "dd/MM/yyyy");
  };

  function currencyFormatter(value) {
    if (typeof value !== "number" || isNaN(value)) {
      return "N/A";
    }

    const formattedValue = value.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    });

    return formattedValue;
  }

  useEffect(() => {
    toast.remove();
  }, []);

  useEffect(() => {
    if (tenderDataIsError) {
      navigate("/tenders");
    }
  }, [tenderDataIsError]);

  useEffect(() => {
    if (updateQuestionsIsLoading) {
      const tempArray = answersStore;
      tempArray.forEach((answer, index) => {
        if (answer.questionGuid === editedQuestionGuid) {
          tempArray[index].answer = null;
        }
      });
      setAnswersStore(tempArray);
    }
  }, [answersStore, updateQuestionsIsLoading]);

  useEffect(() => {
    if (tenderData) {
      const data = {
        ...tenderData,
        createdOn:
          tenderData.createdOn !== null
            ? dateFomatter(tenderData.createdOn)
            : "-",
        dueDate:
          tenderData.dueDate !== null ? dateFomatter(tenderData.dueDate) : "-",
        range: `${currencyFormatter(
          tenderData.minBudget
        )} -> ${currencyFormatter(tenderData.maxBudget)}`,
        status: tenderData.tenderStatus?.description,
      };
      setTender(data);
    }

    if (tenderDataError) {
      toast.error(tenderDataError?.data?.Message || "Something went wrong", {
        duration: 4000,
      });
      return;
    }
  }, [tenderData, tenderDataError]);

  useEffect(() => {
    if (!addQuestionShowModal) {
      setNewQuestions("");
    }
  }, [addQuestionShowModal]);

  const handleSubmitClick = async () => {
    try {
      const response = await submitTender(params.id);

      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 3000,
        });
        return;
      }
      setSubmitModalShow(!submitModalShow);
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 3000,
      });
    }
  };

  const handleQuestionEdit = (question) => {
    setQuestionName(question.question);
    setEditedQuestionGuid(question.guid);
    setAssistanceTypeId(question.assistanceType?.id);
    setEditedQuestion(question.question);
    setSuggestedAnswer(question.suggestedAnswer);
    setAdditionalNote(question.additionalNote);
  };

  const handleSaveEditedQuestions = async () => {
    try {
      const response = await updateQuestions({
        guid: params.id,
        questions: [
          {
            guid: editedQuestionGuid,
            question: questionRef.current[editedQuestionGuid]?.value,
            suggestedAnswer: suggestedAnswer,
            assistanceTypeId: assistanceTypeId,
            additionalNote: additionalNote,
            finalizeAnswer:
              finalizedAnswerRef.current[editedQuestionGuid]?.value,
          },
        ],
      });

      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 3000,
        });
        return;
      }
      toast.success("Question Successfully Updated", {
        duration: 2000,
      });
      setEditedQuestion(null);
      setEditedQuestionGuid(null);
      setQuestionName(null);
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 3000,
      });
    }
  };

  const handleAddTenderQuestionOnClick = async () => {
    const canSave = Boolean(newQuestions);

    if (!canSave) {
      if (newQuestions.length === 0) {
        toast.error("Question is required...!");
      }
      return;
    }

    try {
      const response = await addTenderQuestion({
        guid: params.id,
        questions: newQuestions,
      });
      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 3000,
        });
        return;
      }
      toast.success("Question Added Successfully", {
        duration: 3000,
      });
      setNewQuestions([]);
      setAddQuestionShowModal(!addQuestionShowModal);
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 3000,
      });
    }
  };

  const handleDeleteQuestion = async () => {
    try {
      const response = await deleteQuestion(deletableQuestionGuid);

      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 3000,
        });
        return;
      }
      setShowModal(!showModal);
      setAnswersStore([
        ...answersStore.filter(
          (answer) => answer.questionGuid !== deletableQuestionGuid
        ),
      ]);
      toast.success("Question Successfully Deleted", {
        duration: 3000,
      });
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 3000,
      });
    }
  };

  const capitalizeWordFunction = (text) => {
    if (text) {
      if (text === "it" || text === "It") {
        return text.toUpperCase();
      }
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  };

  const capitalizeEachWordFunction = (text) => {
    if (text) {
      let tempArray = text.split(" ");
      tempArray = tempArray.map((text) => capitalizeWordFunction(text));
      let newText = "";
      for (let i = 0; i < tempArray.length; i++) {
        newText = newText + tempArray[i] + " ";
      }
      return newText;
    }
  };

  return (
    <div className="upgrade-tender">
      {(tenderDataIsFetching ||
        addTenderQuestionIsLoading ||
        updateQuestionsIsLoading ||
        deleteQuestionIsLoading ||
        submitTenderIsLoading) && <LoadingSpinner />}
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="card general">
        <div className="card-body p-5">
          <div className="row">
            <div className="col-6">
              <h5 className="mb-4">General Info</h5>
              <div className="row">
                <div className="col-6">
                  <p>Posted Date</p>
                  <p>Due Date</p>
                  <p>Range</p>
                  <p>Company</p>
                </div>
                <div className="col-6 answers">
                  <p>{tender.createdOn}</p>
                  <p>{tender.dueDate}</p>
                  <p>{tender.range}</p>
                  <p>{capitalizeEachWordFunction(tender.companyName)}</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <h5 className="mb-4">Requirements</h5>
              <div className="row">
                <div className="col-6">
                  <p>Industry</p>
                  <p>Specialties</p>
                  {/* <p>Customization Options</p>
                  <p>Customization Options</p> */}
                </div>
                <div className="col-6 answers">
                  <p>{capitalizeWordFunction(tender.industry)}</p>
                  <p>{capitalizeEachWordFunction(tender.speciality)}</p>
                  {/* <p>Low/Medium/High</p>
                  <p>Low/Medium/High</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body p-5">
          <div className="row answers summary">
            <h5 className="mb-3">Summary</h5>
            <p>
              {tender?.summary
                ?.split("*")
                ?.map(
                  (text) =>
                    text !== "" && <li>{capitalizeWordFunction(text)}</li>
                )}
            </p>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <FinalizedAnswer
            isTenderSubmitted={tender.status === "Submit"}
            questionRef={questionRef}
            finalizedAnswerRef={finalizedAnswerRef}
            questionName={questionName}
            editedQuestion={editedQuestion}
            setDeletableQuestionGuid={setDeletableQuestionGuid}
            deleteFunction={handleDeleteQuestion}
            updateFunction={handleSaveEditedQuestions}
            editFunction={handleQuestionEdit}
            addQuestionShowModal={setAddQuestionShowModal}
            setShowModal={setShowModal}
          />
        </div>

        <AddTenderQuestionModal
          showModal={addQuestionShowModal}
          closeModal={setAddQuestionShowModal}
          value={newQuestions}
          setValue={setNewQuestions}
          confirmSubmit={handleAddTenderQuestionOnClick}
        />
        <DeleteConfirmationModal
          type={"question"}
          showModal={showModal}
          closeModal={setShowModal}
          confirmDelete={handleDeleteQuestion}
        />
        <SubmittedModal
          showModal={submitModalShow}
          closeModal={setSubmitModalShow}
          navigatePage={`/tenders/${params.title}/${params.id}/tender-submit`}
        />
      </div>

      <div className="card documents">
        <div className="card-body p-5">
          <div className="row">
            <h5 className="mb-4">Documents</h5>
            {tender?.documents?.length ? (
              tender.documents.map((document) => (
                <div key={document.id} className="row align-items-center mb-4">
                  <div className="col-9 d-flex align-items-center gap-2">
                    <img src={PdfIcon} alt="pdf" width={25} height={25} />
                    <p className="m-0">{document.name}</p>
                  </div>
                  <div className="col-auto">
                    <a
                      rel="noreferrer"
                      href={document.googleDriveUrl}
                      target="_blank"
                    >
                      <DownloadIcon
                        width={25}
                        height={25}
                        style={{ cursor: "pointer" }}
                      />
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <p>No documents</p>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button className="btn back" onClick={() => navigate("/dashboard")}>
            Back
          </button>
        </div>
        <div className="col-auto">
          <button
            className={`btn ${
              tender.status === "Submit" ? "review" : "submit"
            }`}
            onClick={
              tender.status === "Submit"
                ? tender?.review
                  ? () =>
                      navigate(
                        `/tenders/${params.title}/${params.id}/tender-review`
                      )
                  : () =>
                      navigate(
                        `/tenders/${params.title}/${params.id}/tender-submit`
                      )
                : handleSubmitClick
            }
          >
            {tender.status === "Submit"
              ? tender?.review
                ? "Review"
                : "Give Review"
              : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}
