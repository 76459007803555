import { React, useEffect, useState } from "react";
import "./FilterModal.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useGetTenderStatusesQuery } from "../../../features/Dashboard/DashboardSlice";

const FilterModal = ({ closeModal, showModal, confirmSubmit, filters, setFilters }) => {
  const [tenderStatusList, setTenderStatusList] = useState([]);

  const {
    data: tenderStatus,
    isLoading: tenderStatusIsLoading,
    isSuccess: tenderStatusIsSuccess,
  } = useGetTenderStatusesQuery();

  useEffect(() => {
    if (tenderStatus) {
      const data = tenderStatus.ids.map((id) => tenderStatus.entities[id]);
      setTenderStatusList(data);
    }
  }, [tenderStatus]);

  const handleFilterInputs = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? (checked ? [...prevFilters[name], value] : prevFilters[name].filter(item => item !== value)) : value
    }));
  };

  return (
    <Modal
      style={{ padding: 0 }}
      isOpen={showModal}
      className="top-right-modal"
    >
      <ModalHeader
        className="filter-modal-header px-3"
        toggle={() => closeModal(!showModal)}
      >
        <span className="filter-modal-title">Filter</span>
      </ModalHeader>
      <ModalBody className="filter-modal text-secondary">
        <div className="row mb-1">
          <div className="col px-2">
            <div>
              <div className="px-2 py-1">
                <label htmlFor="CompanyName" className="py-1">Company Name</label>
                <input
                  type="text"
                  name="companyName"
                  className="form-control filter-modal-input pl-3"
                  placeholder="Company Name"
                  value={filters?.companyName}
                  onChange={handleFilterInputs}
                />
              </div>
              <div className="px-2">
                <label htmlFor="TenderName" className="py-1">Tender Name</label>
                <input
                  type="text"
                  name="tenderName"
                  className="form-control filter-modal-input w-100 h-100 pl-3"
                  placeholder="Tender Name"
                  value={filters?.tenderName}
                  onChange={handleFilterInputs}
                />
              </div>
              <div className="px-2 py-1">
                <label htmlFor="DueDate" className="py-1">Due Date</label>
                <input
                  type="date"
                  name="dueDate"
                  className="form-control filter-modal-input date-picker"
                  value={filters?.dueDate}
                  onChange={handleFilterInputs}
                />
              </div>
            </div>
          </div>
          <div className="col-5 pt-2">
            <span className="filter-title">Tender Status</span>
            {tenderStatusList.map(({ name, id }) => (
              <div className="form-check pt-2" key={id}>
                <input
                  key={id}
                  type="checkbox"
                  className="form-check-input"
                  checked={filters.tenderStatus.includes(String(id))}
                  name="tenderStatus"
                  value={id}
                  onChange={handleFilterInputs}
                />
                <label className="form-check-label">{name}</label>
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="tender-question-modal d-flex justify-content-between">
        <Button
          color="secondary"
          className="btn-cancel-modal"
          onClick={() => closeModal(!showModal)}
        >
          Reset Filter
        </Button>
        <Button
          color="primary"
          className="btn-confirm-modal"
          onClick={confirmSubmit}
        >
          Apply Filter
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FilterModal;
