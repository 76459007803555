import React, { useEffect } from "react";
import "./SideBar.css";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import dashbordIcon from "../../assets/images/icons/nav/dashboard.svg";
import researchIcon from "../../assets/images/icons/nav/research.svg";
import knowledgebaseIcon from "../../assets/images/icons/nav/knowledgebase.svg";
import configurationIcon from "../../assets/images/icons/nav/configuration.svg";
import workbookIcon from "../../assets/images/icons/nav/workbook.svg";
import dashbordIconActive from "../../assets/images/icons/nav/dashboard_active.svg";
import researchIconActive from "../../assets/images/icons/nav/research_active.svg";
import knowledgebaseIconActive from "../../assets/images/icons/nav/knowledgebase_active.svg";
import configurationIconActive from "../../assets/images/icons/nav/configuration_active.svg";
import workbookIconActive from "../../assets/images/icons/nav/workbook_active.svg";
import closeIcon from "../../assets/images/icons/close.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser, logOut } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import logoutIcon from "../../assets/icons/logout.svg";
import { authURL } from "../../utils/authURL";

const SideBar = ({ toggleSidebar }) => {
  const location = useLocation();
  const isNavLinkActive = (path) => location.pathname.includes(path);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const handleLogout = () => {
    window.history.replaceState(null, null, window.location.pathname);
    dispatch(logOut());
  };

  useEffect(() => {
    if (!user) {
      handleLogout();
    }
  }, [user]);

  return (
    <div className="sidebar-container">
      <div>
        <h5 className="nav-brand text-center">
          <img src={logo} className="sidebar-tenderAI-logo" width={88} alt="" />
        </h5>
        <div className="list-group list-group-flush scrollarea menu-link ">
          <NavLink
            to="tenders"
            className={`list-group-item list-group-item-action  lh-sm sidebar-nav-link`}
          >
            <img
              className="me-1"
              src={
                isNavLinkActive("tenders") ? dashbordIconActive : dashbordIcon
              }
              width={22}
              alt=""
            />
            <span>Tenders</span>
          </NavLink>

          <NavLink
            to="research"
            className={`list-group-item list-group-item-action lh-sm sidebar-nav-link`}
          >
            <img
              className="me-1"
              src={
                isNavLinkActive("research") ? researchIconActive : researchIcon
              }
              width={22}
              alt=""
            />
            <span>Research</span>
          </NavLink>

          <NavLink
            to="knowledge-base"
            className={`list-group-item list-group-item-action  lh-sm sidebar-nav-link`}
          >
            <img
              className="me-1"
              src={
                isNavLinkActive("knowledge-base")
                  ? knowledgebaseIconActive
                  : knowledgebaseIcon
              }
              width={22}
              alt=""
            />
            <span>Knowledge Base</span>
          </NavLink>
          <NavLink
            to="configuration"
            className={`list-group-item list-group-item-action  lh-sm sidebar-nav-link`}
          >
            <img
              className="me-1"
              src={
                isNavLinkActive("configuration")
                  ? configurationIconActive
                  : configurationIcon
              }
              width={22}
              alt=""
            />
            <span>Configuration</span>
          </NavLink>
        </div>
      </div>
      <div className="avatar-dropdown-container logout-dev">
        <div className="btn-group ">
          <Link
            to={`${authURL}/#/user-management/user-details/${user?.firstName} ${user?.lastName}/${user.guid}`}
            target="_blank"
          >
            <div className="avatar-container">
              <img
                src={`${authURL}/v1/companies/users/${user?.guid}/profile-picture`}
                alt="Avatar"
                className="avatar-img"
              />
            </div>
          </Link>
          <div className="user-details">
            <Link
              to={`${authURL}/#/user-management/user-details/${user?.firstName} ${user?.lastName}/${user.guid}`}
              target="_blank"
            >
              <p className="fw-bold m-0">
                {user?.firstName} {user?.lastName}
              </p>
            </Link>
            <span className="role-text text-justify user-role-description">
              {user?.roles[0]?.description}
            </span>
          </div>
          <button
            type="button"
            className="btn btn-user logout-icon-container"
            onClick={handleLogout}
            title="logout"
          >
            <img src={logoutIcon} width={18} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
