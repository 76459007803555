import React, { useEffect, useState, useRef, useMemo } from "react";
import "./Dashboard.css";
import totalIcon from "../../assets/images/icons/overview/total.svg";
import completedIcon from "../../assets/images/icons/overview/completed.svg";
import inprogressIcon from "../../assets/images/icons/overview/inprogress.svg";
import overdueIcon from "../../assets/images/icons/overview/overdue.svg";
import dotIcon from "../../assets/images/icons/dots.svg";
import SearchBarIcon from "../../assets/icons/07.svg";
import searchIcon from "../../assets/images/icons/search.svg";
import DataTable from "../../components/common/DataTable/DataTable";
import { ReactComponent as TenderDownloadIcon } from "../../assets/images/icons/download.svg";
import TimeIcon from "../../assets/images/icons/Icons (3).svg";
import WarningIcon from "../../assets/images/icons/Icons (4).svg";
import { ReactComponent as GraterThanIcon } from "../../assets/images/icons/greaterthan.svg";
import {
  useGetAllTendersQuery,
  useGetTenderInsightsQuery,
} from "./DashboardSlice";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { format, parseISO } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import FilterModal from "../../components/common/FilterModal/FilterModal";
import correctIcon from "../../assets/icons/correct.svg";
import toast, { Toaster } from "react-hot-toast";
import { skipToken } from "@reduxjs/toolkit/query";

const Dashboard = () => {
  const navigate = useNavigate();
  const gridRef = useRef();
  const [tenderInsights, setTenderInsights] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tenders, setTenders] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [query, setQuery] = useState(null);
  const [filters, setFilters] = useState({
    companyName: "",
    tenderName: "",
    dueDate: "",
    tenderStatus: [],
  });
  const [searchValue, setSearchValue] = useState("");
  const [shouldFetch, setShouldFetch] = useState(null);

  const {
    data: tenderInsightsData,
    isLoading: tenderInsightsDataIsLoading,
    isFetching: tenderInsightsDataIsFetching,
    isSuccess: tenderInsightsDataIsSuccess,
    isError: tenderInsightsDataIsError,
    error: tenderInsightsDataError,
    refetch: tenderInsightsDataRefetch,
  } = useGetTenderInsightsQuery();

  const {
    data: allTendersData,
    isLoading: allTendersDataIsLoading,
    isFetching: allTendersDataIsFetching,
    isSuccess: allTendersDataIsSuccess,
    isError: allTendersDataIsError,
    error: allTendersDataError,
    refetch: allTendersDataRefetch,
  } = useGetAllTendersQuery({
    page: page,
    pageSize: pageSize,
    filters: !query ? false : query,
    searchQuery: searchValue,
  });

  const dateFomatter = (dateString) => {
    return format(parseISO(dateString), "dd/MM/yyyy");
  };

  const timeFomatter = (timeString) => {
    return format(parseISO(timeString), "HH:mm");
  };

  function currencyFormatter(value) {
    if (typeof value !== "number" || isNaN(value)) {
      return "N/A";
    }

    const formattedValue = value.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    });

    return formattedValue;
  }

  useEffect(() => {
    toast.remove();
  }, []);

  useEffect(() => {
    if (tenderInsightsData) {
      const data = tenderInsightsData.ids.map((id) => {
        return tenderInsightsData.entities[id];
      });
      setTenderInsights(data[0]);
    }
  }, [tenderInsightsData]);

  useEffect(() => {
    if (allTendersData) {
      const data = allTendersData.items?.map((item) => {
        const dueDate = item.dueDate !== null ? new Date(item.dueDate) : "";
        let timeDiff =
          item.dueDate !== null ? dueDate.getTime() - new Date().getTime() : "";
        let overdue = false;
        if (timeDiff < 0) {
          timeDiff = timeDiff * -1;
          overdue = true;
        }
        if (timeDiff / (1000 * 3600 * 24) >= 1) {
          timeDiff = Math.round(timeDiff / (1000 * 3600 * 24)) + " days";
        } else if (timeDiff / (1000 * 3600) >= 1) {
          timeDiff = Math.round(timeDiff / (1000 * 3600)) + " hours";
        } else if (timeDiff / (1000 * 60) >= 1) {
          timeDiff = Math.round(timeDiff / (1000 * 60)) + " minutes";
        } else {
          timeDiff = Math.round(timeDiff / 1000) + " seconds";
        }
        return {
          guid: item.guid,
          companyName: item.companyName,
          url: item.url,
          title: item.name,
          status: "",
          googleDriveLocation: item.googleDriveLocation,
          postedDate:
            item.createdOn !== null ? dateFomatter(item.createdOn) : "-",
          dueDate: item.dueDate !== null ? dateFomatter(item.dueDate) : "-",
          budgetAllocation: `${currencyFormatter(item.minBudget)} -> ${currencyFormatter(item.maxBudget)}`,
          countdown:
            item.tenderStatus.description === "Submit"
              ? { status: "submit", time: 0 }
              : timeDiff !== null
              ? !overdue
                ? {
                    status: "remaining",
                    time: timeDiff,
                  }
                : {
                    status: "overdue",
                    time: timeDiff,
                  }
              : "-",
          tenderStatus:
            item.tenderStatus !== null ? item.tenderStatus.description : "-",
          submittedOn:
            item.tenderStatus.submittedOn !== null
              ? {
                  date: dateFomatter(item?.tenderStatus?.submittedOn),
                  time: timeFomatter(item?.tenderStatus?.submittedOn),
                }
              : "-",
        };
      });
      setTenders(data);
      setTotal(allTendersData.total);
      setTotalPages(allTendersData.totalPages);
    }
    if (allTendersDataError) {
      toast.error(allTendersDataError?.data?.Message, {
        duration: 2000,
      });
      return;
    }
  }, [allTendersData, allTendersDataError]);

  const getStatusColor = (statusName) => {
    switch (statusName) {
      case "Active":
        return "active-badge";
      case "Completed":
        return "completed-badge";
      case "In Progress":
        return "in-progress-badge";
      case "Over Due":
        return "over-due-badge";
      case "Submit":
        return "submit-badge";
      default:
        return;
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const closeModal = () => {
    setQuery(null);
    setFilters({
      companyName: "",
      tenderName: "",
      dueDate: "",
      tenderStatus: [],
    });
    setShowFilterModal(false);
  };

  const handleSearchFilterOnClick = () => {
    setQuery(filters);
    setShowFilterModal(false);
    allTendersDataRefetch();
  };

  function ShortText(text, maxLength) {
    if (text.length <= maxLength) {
      return <span>{text}</span>;
    }
    return <span>{text.slice(0, maxLength)}...</span>;
  }

  const newColumns = [
    {
      key: "company",
      field: "companyName",
      headerText: "Company",
      width: "160",
      alignment: "Left",
      template: (props) => (
        <div className="tender-company" title={props?.companyName}>
          <span>{ShortText(props?.companyName,12)}</span>
        </div>
      ),
    },
    {
      key: "url",
      field: "url",
      headerText: "Tender URL",
      width: "160",
      alignment: "Left",
      template: (props) => (
        <div className="tender-url" title={props?.url}>
          <Link to={props.url} target="_blank">
            {ShortText(props?.url, 20)}
          </Link>
        </div>
      ),
    },
    {
      key: "title",
      field: "title",
      headerText: "Title",
      width: "150",
      alignment: "Left",
      template: (props) => (
        <div className="tender-title" title={props?.title}>
          <span>{ShortText(props?.title,12)}</span>
        </div>
      ),
    },
    {
      key: "status",
      field: "tenderStatus",
      headerText: "Status",
      width: "130",
      alignment: "Left",
      template: (props) => (
        <div className="company d-flex align-items-center tende-status">
          <div
            className={`status-badge ${getStatusColor(props?.tenderStatus)}`}
          ></div>
          <div>{props?.tenderStatus}</div>
        </div>
      ),
    },
    {
      key: "postedDate",
      field: "postedDate",
      headerText: "Posted Date",
      width: "125",
      alignment: "Left",
    },
    {
      key: "dueDate",
      field: "dueDate",
      headerText: "Due Date",
      width: "120",
      alignment: "Left",
    },
    {
      key: "budgetAllocation",
      field: "budgetAllocation",
      headerText: "Budget Allocation",
      width: "220",
      alignment: "Left",
      template: (props) => (
        <div className="tender-budget" title={props?.budgetAllocation}>
          <span>{props?.budgetAllocation}</span>
        </div>
      ),
    },
    {
      key: "countdown",
      field: "countdown",
      headerText: "Countdown/Timer",
      width: "200",
      alignment: "Left",
      template: (props) => (
        <div className="countdown-timer">
          <div className="d-flex align-items-center">
            {props.countdown?.status === "submit" && (
              <div className="submitted-texts">
                <div className="submitted-status">
                  <img
                    className="p-1"
                    src={correctIcon}
                    alt="correctIcon"
                    width={25}
                    height={25}
                  />
                  <span> Submitted</span>
                </div>
                <p>
                  on {props.submittedOn.date} at {props.submittedOn.time}
                </p>
              </div>
            )}
            {props.countdown?.status === "remaining" && (
              <div className="remaining-status">
                <img className="p-1" src={TimeIcon} alt="TimeIcon" />
                <span>{props.countdown?.time} remaining</span>
              </div>
            )}
            {props.countdown?.status === "overdue" && (
              <div className="overdue-status">
                <img className="p-1" src={WarningIcon} alt="WarningIcon" />
                <span>{props.countdown?.time} Over Due</span>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      key: "option",
      field: "",
      headerText: "Action",
      width: "110",
      alignment: "Left",
      template: (props) => (
        <div className="option">
          <div className="d-flex gap-1">
            <div className="px-1">
              <Link to={props.googleDriveLocation} target="_blank">
                <TenderDownloadIcon className="option-icon" />
              </Link>
            </div>
            <div
              onClick={() =>
                navigate(`/tenders/${props?.title}/${props?.guid}`)
              }
            >
              <GraterThanIcon className="option-icon" />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="content-container">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {tenderInsightsDataIsFetching && <LoadingSpinner />}
      <div className="overview">
        <div className="row gap-2 align-items-center justify-content-between">
          <div className="col-sm-12 col-md">
            <div className="custom-card">
              <div className="overview-card">
                <div className="overview-header">
                  <img src={dotIcon} alt="more" />
                  <img src={totalIcon} alt="total Icon" />
                </div>
                <div className="overview-body">
                  <span className="overview-title">TOTAL</span>
                  <h3 className="overview-value">
                    {tenderInsights?.totalCount}
                  </h3>
                </div>
                <div className="colored-border purple-border"></div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md">
            <div className="custom-card">
              <div className="overview-card">
                <div className="overview-header">
                  <img src={dotIcon} alt="more" />
                  <img src={completedIcon} alt="total Icon" />
                </div>
                <div className="overview-body">
                  <span className="overview-title">COMPLETED</span>
                  <h3 className="overview-value">
                    {tenderInsights?.completedCount}
                  </h3>
                </div>
                <div className="colored-border green-border"></div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md">
            <div className="custom-card">
              <div className="overview-card">
                <div className="overview-header">
                  <img src={dotIcon} alt="more" />
                  <img src={inprogressIcon} alt="total Icon" />
                </div>
                <div className="overview-body">
                  <span className="overview-title">IN PROGRESS</span>
                  <h3 className="overview-value">
                    {tenderInsights?.inProgressCount}
                  </h3>
                </div>
                <div className="colored-border orange-border"></div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md">
            <div className="custom-card">
              <div className="overview-card">
                <div className="overview-header">
                  <img src={dotIcon} alt="more" />
                  <img src={overdueIcon} alt="total Icon" />
                </div>
                <div className="overview-body">
                  <span className="overview-title">OVERDUE</span>
                  <h3 className="overview-value">
                    {tenderInsights?.overdueCount}
                  </h3>
                </div>
                <div className="colored-border red-border"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md mt-3">
        <div className="custom-card ">
          <div className="row gap-2 justify-content-between align-items-center">
            <div className="col-12 col-md-2 border-2 px-4">
              <div className="d-flex">
                <div>
                  <select
                    className="border-0 page-selector"
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={10}>Display 10 Tenders</option>
                    <option value={20}>Display 20 Tenders</option>
                    <option value={50}>Display 50 Tenders</option>
                    <option value={100}>Display 100 Tenders</option>
                  </select>
                </div>
                <div className="px-4">
                  <button
                    className="create-tender-btn"
                    onClick={() => navigate("/tenders/create-tender")}
                  >
                    Create Tender
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 border-2 d-flex justify-content-end py-3 px-4">
              <div style={{ position: "relative" }}>
                <div className="search-icon">
                  <img src={searchIcon} alt="Search" />
                </div>
                <div className="px-2">
                  <input
                    id="file-search-input"
                    type="text"
                    className="form-control custom-input w-100 h-100 pl-3"
                    placeholder="Search Tender..."
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div>
                <button
                  onClick={() => setShowFilterModal(true)}
                  className="btn p-0"
                >
                  <img src={SearchBarIcon} alt="filter" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="px-1 tender-details-table-content w-100">
              <DataTable
                data={tenders}
                grid={gridRef}
                columns={newColumns}
                defaultPaging={false}
                setPage={setPage}
                pageSize={pageSize}
                pageCount={totalPages}
                totalRecords={total}
                selectable={true}
                navigateUrl={"/tenders/"}
              />
            </div>
          </div>
        </div>
        <FilterModal
          showModal={showFilterModal}
          closeModal={closeModal}
          filters={filters}
          setFilters={setFilters}
          confirmSubmit={handleSearchFilterOnClick}
        />
      </div>
    </div>
  );
};

export default Dashboard;
