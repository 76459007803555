import React, { useEffect } from "react";
import "./SideBar.css";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import dashbordIcon from "../../assets/images/icons/nav/dashboard.svg";
import researchIcon from "../../assets/images/icons/nav/research.svg";
import knowledgebaseIcon from "../../assets/images/icons/nav/knowledgebase.svg";
import configurationIcon from "../../assets/images/icons/nav/configuration.svg";
import dashbordIconActive from "../../assets/images/icons/nav/dashboard_active.svg";
import researchIconActive from "../../assets/images/icons/nav/research_active.svg";
import knowledgebaseIconActive from "../../assets/images/icons/nav/knowledgebase_active.svg";
import configurationIconActive from "../../assets/images/icons/nav/configuration_active.svg";
import closeIcon from "../../assets/images/icons/close.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser, logOut } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import logoutIcon from "../../assets/icons/logout.svg";
import spandIcon from "../../assets/images/spandIcon.png";
import { authURL } from "../../utils/authURL";

const CollapsedSideBar = ({ toggleSidebar }) => {
  const location = useLocation();
  const isNavLinkActive = (path) => location.pathname.includes(path);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const handleLogout = () => {
    window.history.replaceState(null, null, window.location.pathname);
    dispatch(logOut());
  };

  useEffect(() => {
    if (!user) {
      handleLogout();
    }
  }, [user]);
  return (
    <div>
      <div>
        <h5 className="nav-brand text-center">
          <img className="tenderAI-logo" src={logo} width={50} alt="" />
        </h5>
        <div className="tender-sidebar-container">
          <div className="list-group list-group-flush scrollarea menu-link ">
            <NavLink
              to="tenders"
              className={`list-group-item list-group-item-action  lh-sm sidebar-nav-link`}
            >
              <img
                className="me-1"
                src={
                  isNavLinkActive("tenders") ? dashbordIconActive : dashbordIcon
                }
                width={30}
                alt=""
              />
            </NavLink>

            <NavLink
              to="research"
              className={`list-group-item list-group-item-action lh-sm sidebar-nav-link`}
            >
              <img
                className="me-1"
                src={
                  isNavLinkActive("research")
                    ? researchIconActive
                    : researchIcon
                }
                width={30}
                alt=""
              />
            </NavLink>

            <NavLink
              to="knowledge-base"
              className={`list-group-item list-group-item-action  lh-sm sidebar-nav-link`}
            >
              <img
                className="me-1"
                src={
                  isNavLinkActive("knowledge-base")
                    ? knowledgebaseIconActive
                    : knowledgebaseIcon
                }
                width={30}
                alt=""
              />
            </NavLink>
            <NavLink
              to="configuration"
              className={`list-group-item list-group-item-action  lh-sm sidebar-nav-link`}
            >
              <img
                className="me-1"
                src={
                  isNavLinkActive("configuration")
                    ? configurationIconActive
                    : configurationIcon
                }
                width={30}
                alt=""
              />
            </NavLink>
          </div>

          <div className="avatar-dropdown-container logout-dev">
            <div className="btn-group ">
              <Link
                to={`${authURL}/#/user-management/user-details/${user?.firstName} ${user?.lastName}/${user.guid}`}
                target="_blank"
              ></Link>
              <button
                type="button"
                className="btn btn-user"
                onClick={handleLogout}
                title="logout"
              >
                <img
                  className="logout-icon"
                  src={logoutIcon}
                  width={34}
                  alt=""
                />
              </button>
            </div>
          </div>
          <div
            className="collapse-icon-container"
            onClick={() => toggleSidebar(true)}
          >
            <div className="spand-icon-container">
              <img src={spandIcon} width={12} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsedSideBar;
