import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "./BreadcrumbsComponent.css";
import backWardIcon from "../../../assets/images/icons/lessthan.svg";

export default function BreadcrumbsComponent() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const capitalizeFunction = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const capitalizeEachWordFunction = (text) => {
    let tempArray = text.split(" ");
    tempArray = tempArray.map((text) => capitalizeFunction(text));
    let newText = "";
    for (let i = 0; i < tempArray.length; i++) {
      newText = newText + tempArray[i] + " ";
    }
    return newText;
  };

  useEffect(() => {
    let crumbs = location.pathname.split("/").filter((crumb) => crumb !== "");

    crumbs = crumbs.map((crumb, index) => {
      if (
        crumbs.includes("tenders") &&
        crumbs.includes(params.id) &&
        index === crumbs.indexOf("tenders") + 2
      ) {
        return null;
      } else {
        let pathname = "";
        for (let i = 0; i <= index; i++) {
          pathname = pathname + "/" + crumbs[i];
        }
        if (
          index === crumbs.indexOf("tenders") + 1 &&
          crumbs.includes(params.id)
        ) {
          pathname = pathname + "/" + crumbs[index + 1];
          crumb = decodeURIComponent(crumbs[index]);
          crumb = capitalizeEachWordFunction(crumb);
        }

        if (crumb.includes("-") && !crumb.includes("Tenders")) {
          crumb = crumb.split("-");
          let tempCrumb = crumb[0];
          for (let index = 1; index < crumb.length; index++) {
            tempCrumb = tempCrumb + " " + capitalizeFunction(crumb[index]);
          }
          crumb = tempCrumb;
        }

        crumb = capitalizeFunction(crumb);

        return crumb !== "Tenders" ? (
          <div key={crumb} className="d-flex gap-1">
            <img
              src={backWardIcon}
              width={35}
              height={35}
              alt="back"
              onClick={() =>{
                if(pathname.includes("tenders")){
                  navigate('/');
                }else{
                  navigate(pathname);
                }
              }}
              style={{ cursor: "pointer" }}
            />
            <Link to={pathname}>{crumb}</Link>
          </div>
        ) : (
          ""
        );
      }
    });

    setBreadcrumbs(crumbs);
  }, [location.pathname, params.id]);

  return <div className="breadcrumbs">{breadcrumbs}</div>;
}
