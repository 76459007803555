import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { format, parseISO } from "date-fns";

export const tenderSubmitSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addTenderReview: builder.mutation({
      query: (result) => ({
        url: `/tenders/${result?.guid}:review`,
        method: "POST",
        body: {
          score: result?.score,
          feedbackComment: result?.feedbackComment,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Tenders", id: "LIST" },
        { type: "Tenders", id: arg.guid },
      ],
    }),
  }),
});

export const { useAddTenderReviewMutation } = tenderSubmitSlice;
