import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const tenderInsightsAdapter = createEntityAdapter({
  selectId: (entity) => entity.uniqueKey,
  initialState: null,
});

const questionAnswersAdapter = createEntityAdapter({
  selectId: (entity) => entity.uniqueKey,
  initialState: null,
});

const tenderStatusesAdapter = createEntityAdapter({
  selectId: (status) => status.id,
});

const tenderStatusesInitialState = tenderStatusesAdapter.getInitialState();
const tenderInsightsInitialState = tenderInsightsAdapter.getInitialState();
const questionAnswersInitialState = questionAnswersAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTenders: builder.query({
      query: ({ page, pageSize, filters, searchQuery }) => {
        let queryString = `/tenders?page=${page}&pageSize=${pageSize}&Query=${searchQuery}`;
        if (filters) {
          if (filters?.companyName) {
            queryString += `&companyName=${filters?.companyName}`;
          }
          if (filters?.tenderName) {
            queryString += `&tenderName=${filters?.tenderName}`;
          }
          if (filters?.dueDate) {
            queryString += `&dueDate=${filters?.dueDate}`;
          }
          if (filters?.tenderStatus.length !== 0) {
            filters?.tenderStatus.map((id) => {
              queryString += `&tenderStatusId=${id}`;
            });
          }
        }
        return queryString;
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "Tenders", id: "LIST" }];
        } else {
          return [
            { type: "Tenders", id: "LIST" },
            ...(result?.items || []).map((item) => ({
              type: "Tenders",
              id: item.guid,
            })),
          ];
        }
      },
    }),

    getTender: builder.query({
      query: (guid) => `/tenders/${guid}`,
      providesTags: (result, error, arg) => [{ type: "Tenders", id: arg }],
    }),

    getAllQuestions: builder.query({
      query: (request) =>
        `/tenders/${request.guid}/questions?page=${request.page}&pageSize=${request.pageSize}`,
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "TenderQuestions", id: "LIST" }];
        } else {
          return [
            { type: "TenderQuestions", id: "LIST" },
            ...(result?.items || []).map((item) => ({
              type: "TenderQuestions",
              id: item.guid,
            })),
          ];
        }
      },
    }),

    getTenderInsights: builder.query({
      query: () => `/insights/tenders`,
      transformResponse: (responseData) => {
        return tenderInsightsAdapter.addOne(
          tenderInsightsInitialState,
          responseData
        );
      },
      providesTags: (result, error, arg) => [
        { type: "TenderInsights", id: "DEFAULT_ID" },
      ],
    }),

    getQuestionAnswers: builder.query({
      query: (guid) => `/tenders/questions/${guid}/answers`,
      transformResponse: (responseData) => {
        return questionAnswersAdapter.addOne(
          questionAnswersInitialState,
          responseData
        );
      },
      providesTags: (result, error, arg) => [
        { type: "Answers", id: "DEFAULT_ID" },
      ],
    }),

    submitTender: builder.mutation({
      query: (guid) => ({
        url: `/tenders/${guid}:submit`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Tenders", id: "LIST" },
        { type: "Tenders", id: arg },
      ],
    }),

    updateQuestions: builder.mutation({
      query: (questions) => ({
        url: `/tenders/${questions.guid}/questions`,
        method: "PUT",
        body: { ...questions },
      }),
      invalidatesTags: [{ type: "TenderQuestions", id: "LIST" }],
    }),

    deleteQuestion: builder.mutation({
      query: (guid) => ({
        url: `/tenders/questions/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "TenderQuestions", id: "LIST" },
        { type: "TenderQuestions", id: arg },
      ],
    }),
    getTenderStatuses: builder.query({
      query: () => `/tender-statuses`,
      transformResponse: (responseData) => {
        const loadedStatuses = responseData;
        return tenderStatusesAdapter.setAll(
          tenderStatusesInitialState,
          loadedStatuses
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "TenderStatuses", id: arg }];
        } else {
          return [
            { type: "TenderStatuses", id: "LIST" },
            ...result.ids.map((id) => ({ type: "TenderStatuses", id })),
          ];
        }
      },
    }),
  }),
});

export const {
  useGetAllTendersQuery,
  useGetTenderQuery,
  useGetTenderStatusesQuery,
  useGetAllQuestionsQuery,
  useGetTenderInsightsQuery,
  useUpdateQuestionsMutation,
  useGetQuestionAnswersQuery,
  useDeleteQuestionMutation,
  useSubmitTenderMutation,
} = extendedApiSlice;
