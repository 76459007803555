import { apiSlice } from "../../app/api/apiSlice";

export const tenderSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createTender: builder.mutation({
      query: (result) => {
        const formData = new FormData();
        Object.entries(result).forEach(([key, value]) => {
          formData.append(key, value);
        });
        return {
          url: `/tenders`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Tenders", id: "LIST" },
        { type: "Tenders", id: arg.guid },
      ],
    }),
    addTenderQuestion: builder.mutation({
      query: (result) => ({
        url: `/tenders/${result?.guid}/questions`,
        method: "POST",
        body: {
          questions: [result?.questions]
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "TenderQuestions", id: "LIST" },
      ],
    }),
  }),
});

export const { useCreateTenderMutation, useAddTenderQuestionMutation } = tenderSlice;
