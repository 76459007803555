import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  PagerComponent,
  DetailRow,
} from "@syncfusion/ej2-react-grids";
import { L10n } from "@syncfusion/ej2-base";
import "./DataTable.css";
import { useNavigate } from "react-router-dom";

L10n.load({
  "en-US": {
    pager: {
      itemsPerPage: "",
      currentPageInfo: "",
      totalItemsInfo: "Showing {1} to {2} of {0}",
    },
  },
});

const DataTable = ({
  data,
  columns,
  grid,
  setPage,
  pageSize,
  totalRecords,
  defaultPaging = true,
  isDetailTemplate = false,
  selectable = false,
  navigateUrl,
}) => {
  const navigate = useNavigate();

  const handlePageClick = (args) => {
    setPage(args.currentPage);
  };

  const tableColumns =
    columns && columns.length > 0
      ? columns.map((column) => (
          <ColumnDirective
            key={column.key}
            field={column.field}
            headerText={column.headerText}
            width={column.width}
            textAlign={column.alignment}
            template={column.template}
          />
        ))
      : null;

  const handleRowHover = () => {
    const gridRows = document.querySelectorAll(".e-row");

    gridRows.forEach((row) => {
      row.addEventListener("mouseover", (e) => {
        const targetRow = e.currentTarget;
        const rowIndex = targetRow.getAttribute("data-rowindex");

        grid.current.detailRowModule.expand(rowIndex);
      });

      row.addEventListener("mouseout", (e) => {
        const targetRow = e.currentTarget;
        const rowIndex = targetRow.getAttribute("data-rowindex");

        grid.current.detailRowModule.collapse(rowIndex);
      });
    });
  };

  const onGridCreated = () => {
    if (isDetailTemplate) {
      handleRowHover();
    }
  };

  return (
    <>
      <div style={{ overflow: "auto" }}>
        <GridComponent
          ref={grid}
          dataSource={data}
          allowPaging={defaultPaging}
          detailTemplate={
            isDetailTemplate
              ? (rowData) => {
                  return (
                    <div
                      className="text-center py-3 m-0"
                      style={{ backgroundColor: "#f7f6f0" }}
                    >
                      {/*  */}
                    </div>
                  );
                }
              : undefined
          }
          allowSorting={true}
          allowTextWrap={true}
          allowScrolling={true}
          enableAdaptiveUI={true}
          locale="en-US"
          created={onGridCreated}
          style={{ height: "auto" }}
          selectionSettings={{ mode: "Cell", type: "Single" }}
          cellSelected={
            selectable
              ? (args) =>
                  args.cellIndex.cellIndex !== 8 &&
                  args.cellIndex.cellIndex !== 1 &&
                  navigate(
                    navigateUrl + args.data?.title + "/" + args.data?.guid
                  )
              : ""
          }
          queryCellInfo={(args) => {
            if (args.column.field && selectable) {
              args.cell.classList.add("custom-pointer-cursor");
            }
          }}
        >
          <ColumnsDirective>{tableColumns}</ColumnsDirective>
          <Inject services={[Sort, Page, DetailRow]} />
        </GridComponent>
      </div>
      {!defaultPaging && (
        <PagerComponent
          pageSize={pageSize}
          totalRecordsCount={totalRecords}
          click={handlePageClick}
        />
      )}
    </>
  );
};
export default DataTable;
