import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const researchAdapter = createEntityAdapter({
  selectId: (entity) => entity.uniqueKey,
  initialState: null,
});

const researchInitialState = researchAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getResearchAnswers: builder.query({
      query: (questions) => ({
        url: `/research:query`,
        method: "POST",
        body: { ...questions },
      }),
      transformResponse: (responseData) => {
        return researchAdapter.addOne(researchInitialState, responseData);
      },
      providesTags: [{ type: "ResearchAnswers", id: "DEFAULT_ID" }],
    }),
  }),
});

export const { useGetResearchAnswersQuery } = extendedApiSlice;
