import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const configurationAdapter = createEntityAdapter({
  selectId: (entity) => entity.uniqueKey,
  initialState: null,
});

const departmentAdapter = createEntityAdapter({
  selectId: (department) => department.id,
  sortComparer: (a, b) => b.id - a.id,
});

const personaTonesAdapter = createEntityAdapter({
  selectId: (persona) => persona.id,
  sortComparer: (a, b) => b.id - a.id,
});

const configurationInitialState = configurationAdapter.getInitialState();
const departmentInitialState = departmentAdapter.getInitialState();
const personaTonesInitialState = personaTonesAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConfiguration: builder.query({
      query: () => `/configuration`,
      transformResponse: (responseData) => {
        return configurationAdapter.addOne(
          configurationInitialState,
          responseData
        );
      },
      providesTags: (result, error, arg) => [
        { type: "Configuration", id: "DEFAULT_ID" },
      ],
    }),

    getDepartments: builder.query({
      query: () => `/departments`,
      transformResponse: (responseData) => {
        const loadedDepartments = responseData;
        return departmentAdapter.setAll(
          departmentInitialState,
          loadedDepartments
        );
      },
      providesTags: (result, error, arg) => [
        { type: "Departments", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Departments", id })),
      ],
    }),

    getPersonaTones: builder.query({
      query: () => `/persona-tones`,
      transformResponse: (responseData) => {
        const loadedPersonaTones = responseData;
        return personaTonesAdapter.setAll(
          personaTonesInitialState,
          loadedPersonaTones
        );
      },
      providesTags: (result, error, arg) => [
        { type: "Persona-Tones", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Persona-Tones", id })),
      ],
    }),

    updateConfiguration: builder.mutation({
      query: (configuration) => ({
        url: `/configuration`,
        method: "PUT",
        body: { ...configuration },
      }),
      invalidatesTags: [{ type: "Configuration", id: "DEFAULT_ID" }],
    }),
  }),
});

export const {
  useGetConfigurationQuery,
  useGetDepartmentsQuery,
  useGetPersonaTonesQuery,
  useUpdateConfigurationMutation,
} = extendedApiSlice;
