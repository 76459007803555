import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import expandIcon from "../../assets/icons/expand.svg";
import collapseIcon from "../../assets/images/collapsiIcon.png";
import "./Layout.css";
import BreadcrumbsComponent from "../common/Breadcrumbs/BreadcrumbsComponent";
import CollapsedSideBar from "../SideBar/CollapsedSideBar";

const Layout = () => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className={`layout ${!isSidebarCollapsed ? "collapsed" : ""}`}>
      <div className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
        {isSidebarCollapsed ? (
          <div className="collapse-sidebar">
            <SideBar
              toggleSidebar={toggleSidebar}
              isCollapsed={isSidebarCollapsed}
            />
          </div>
        ) : (
          <div className="collapsed-sidebar">
            <CollapsedSideBar toggleSidebar={setSidebarCollapsed} />
          </div>
        )}
      </div>
      <div className="main p-0">
        <BreadcrumbsComponent />
        <Outlet />
      </div>

      <div
        className={`collapse-section ${!isSidebarCollapsed ? "d-none" : ""}`}
        onClick={toggleSidebar}
      >
        <div className="fw-bold">Collapse Menu</div>
        <div className="collapsi-icon-container">
          <img src={isSidebarCollapsed ? collapseIcon : ""} width={11} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Layout;
