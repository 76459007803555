import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import "./ConfigurationLayout.css";

export default function ConfigurationLayout() {
  return (
    <div className="configuration">
      <nav className="m-0 pt-2">
        <NavLink className="nav-link" to={"/configuration/company"}>
          Company
        </NavLink>
        <NavLink className="nav-link" to={"/configuration/persona"}>
          Persona
        </NavLink>
        <NavLink className="nav-link" to={"/configuration/ethos"}>
          Ethos
        </NavLink>
      </nav>
      <Outlet />
    </div>
  );
}
