import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./AddTenderQuestionModal.css";

const AddTenderQuestionModal = ({
  closeModal,
  showModal,
  confirmSubmit,
  value,
  setValue,
}) => {
  return (
    <Modal
      style={{ padding: 0, width: "700px" }}
      isOpen={showModal}
      centered={true}
      size="lg"
    >
      <ModalHeader
        className="tender-question-modal"
        toggle={() => closeModal(!showModal)}
      >
        <span className="add-question-modal-title">Add Question</span>
      </ModalHeader>
      <ModalBody className="tender-question-modal text-secondary">
        <textarea
          id="tender-question-input"
          className="form-control p-2 mt-3 "
          style={{
            resize: "none",
            fontSize: "14px",
            zIndex: 5,
            padding: "5px",
          }}
          rows={7}
          cols={7}
          placeholder="Enter Question..."
          value={value}
          onChange={(e) => setValue(e.target.value)}
        ></textarea>
      </ModalBody>
      <ModalFooter className="tender-question-modal d-flex justify-content-between">
        <Button
          color="secondary"
          className="btn-cancel-modal"
          onClick={() => closeModal(!showModal)}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="btn-confirm-modal"
          onClick={confirmSubmit}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddTenderQuestionModal;
