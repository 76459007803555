import { React, useEffect, useState } from "react";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { useAddTenderReviewMutation } from "./TenderSubmitSlice";
import "./TenderSubmit.css";
import FeedbackModal from "../../components/common/FeedbackModal/FeedbackModal";

const TenderSubmit = () => {
  let { title, id } = useParams();
  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState("0");
  const [feedbackComment, setFeedbackComment] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [
    addTenderReview,
    {
      isLoading: addTenderReviewIsLoading,
      isSuccess: addTenderReviewIsSuccess,
    },
  ] = useAddTenderReviewMutation();

  useEffect(() => {
    toast.remove();
  }, []);

  const handleSliderChange = (args) => {
    setSliderValue(args?.value);
  };

  const handleTenderReviewSubmit = async () => {
    const canSave = Boolean(feedbackComment);

    if (!canSave) {
      if (feedbackComment === "") {
        toast.error("feedback Comment is required...!");
      }

      return;
    }
    try {
      const response = await addTenderReview({
        guid: id,
        score: sliderValue,
        feedbackComment: feedbackComment,
      }).unwrap();

      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 4000,
        });
        return;
      }

      // toast.success("Tender Review Added Successfully", {
      //   duration: 1500,
      // });
      setShowModal(!showModal);
      handleResetData();
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 4000,
      });
    }
  };

  const handleResetData = () => {
    setFeedbackComment("");
  };

  return (
    <div className="Tender-submit-page">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {addTenderReviewIsLoading && <LoadingSpinner />}
      <div className="row mt-2">
        <div className="col">
          <div className="custom-card pt-5 pb-5">
            <div className="px-5">
              <div style={{ position: "relative", width: "190px" }}>
                <h6 className="text-secondary pb-1">
                  Solution Alignment Score
                </h6>
              </div>
              <div className="p-text">
                <p className="m-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
                  repudiandae, qui cumque vero aliquam error facere accusamus,
                  perferendis earum culpa repellat esse fugiat animi temporibus
                  architecto possimus est ea! Voluptas?
                </p>
              </div>
              <div className="d-flex justify-content-center pt-5 pb-5">
                <div className="w-75 progress-bar-container text-secondary">
                  <div className="sliderwrap">
                    <h6 className="m-0">
                      Score <span>{sliderValue}</span>
                    </h6>
                    <SliderComponent
                      id="slider"
                      type="MinRange"
                      value={sliderValue}
                      immediateRender={true}
                      enableAnimation={true}
                      change={handleSliderChange}
                    ></SliderComponent>
                    <div className="d-flex justify-content-between progress-status-bar">
                      <div>
                        <h6>Accepted</h6>
                      </div>
                      <div>
                        <h6>Require improvement</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-5">
              <div style={{ position: "relative", width: 313 }}>
                <span className="circular-mark"></span>
                <h6 className="text-secondary pb-1">
                  Please Provide Feedback For Improvement
                </h6>
              </div>
              <div className="p-text">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
                  repudiandae, qui cumque vero aliquam error facere accusamus,
                  perferendis earum culpa repellat esse fugiat animi temporibus
                  architecto possimus est ea! Voluptas?
                </p>
              </div>
              <div className="d-flex justify-content-center py-4">
                <div className="w-75">
                  <textarea
                    id="Feedback-text"
                    cols="83"
                    rows="10"
                    placeholder="Write Something..."
                    defaultValue={feedbackComment}
                    onChange={(e) => setFeedbackComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4 submit-tender">
            <button
              className="btn btn-secondary px-3 submit"
              onClick={handleTenderReviewSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <FeedbackModal
        showModal={showModal}
        closeModal={setShowModal}
        navigatePage={`/tenders/${title}/${id}/tender-review`}
        score={sliderValue}
      />
    </div>
  );
};

export default TenderSubmit;
