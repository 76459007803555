import { apiSlice } from "../../app/api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getKnowledgeBaseDocuments: builder.query({
      query: (request) =>
        `/knowledge-based-documents?page=${request.page}&pageSize=${request.pageSize}`,
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "KnowledgeBaseDocuments", id: "LIST" }];
        } else {
          return [
            { type: "KnowledgeBaseDocuments", id: "LIST" },
            ...(result?.items || []).map((item) => ({
              type: "KnowledgeBaseDocuments",
              id: item.documents?.guid,
            })),
          ];
        }
      },
    }),

    addKnowledgeBaseDocuments: builder.mutation({
      query: (formData) => ({
        url: `/knowledge-based-documents`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "KnowledgeBaseDocuments", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useAddKnowledgeBaseDocumentsMutation,
  useGetKnowledgeBaseDocumentsQuery,
} = extendedApiSlice;
