import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./DeleteConfirmationModal.css";

const DeleteConfirmationModal = ({
  type,
  closeModal,
  showModal,
  confirmDelete,
}) => {
  return (
    <Modal style={{ padding: 0 }} isOpen={showModal} centered={true}>
      <ModalHeader toggle={() => closeModal(!showModal)}>
        Delete Confirmation
      </ModalHeader>

      <ModalBody>
        <h6 className="fw-bold">{`Are you sure you want to delete this ${type} ?`}</h6>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button
          color="secondary"
          className="btn-update"
          onClick={() => closeModal(!showModal)}
        >
          Cancel
        </Button>
        <Button color="danger" className="btn-delete" onClick={confirmDelete}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;
